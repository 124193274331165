/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2024/7/25 下午2:58
 */

/*
 * @Author: Freja
 * @Date: 2023-06-12 14:39:55
 * @FilePath: /mobile/src/common/js/config.js
 * @LastEditors: Freja
 * @LastEditTime: 2025-02-17 15:22:33
 */
// const ACCESS_ORIGIN = 'https://api.cmalive.cn/' // 线上环境
// const ACCESS_ORIGIN = 'https://30.100api.cn/' // 开发环境
const ACCESS_ORIGIN = 'https://liveapi.cn/'; // 测试环境API域名

// const DOMAIN_NAME = 'gowell.100doc.com.cn' // 代理域名
// const DOMAIN_NAME = 'shahai.test.100doc.com.cn' // 开发域名
const DOMAIN_NAME = 'changqing.100live.cn'; // 测试域名
// const DOMAIN_NAME = 'yyjk.100doc.com.cn' // 线上域名

module.exports = {
  DOMAIN_NAME,
  ACCESS_ORIGIN
};