/* this file is transformed by vux-loader */
/* eslint-disable */
const caseActivityMy = () => import('@/components/case/case-activity-my'); // 病例征集-我的病例活动
const caseMy = () => import('@/components/case/case-my'); // 病例征集-我的病例
const caseRemarkActivityMy = () => import('@/components/case/case-remark-activity-my'); // 病例征集-我的点评活动
const caseRemarkMy = () => import('@/components/case/case-remark-my'); // 病例征集-我的点评
const PaperRemarkMy = () => import('@/components/case/paper/paper-remark-my'); // 论文征集-我的点评
const RemarkStart = () => import('@/components/case/remarkStart'); // 点评开始

export let myRouters = [{
  path: '/caseMy',
  component: caseActivityMy,
  name: '个人中心'
},
// 病例征集-我的病例活动
{
  path: '/caseMy/:activityId',
  component: caseMy,
  name: '个人中心'
},
// 病例征集-我的病例
{
  path: '/my/myCaseList/:activityId',
  component: caseMy,
  name: '个人中心'
},
// 病例征集-我的病例
{
  path: '/caseRemarkMy',
  component: caseRemarkActivityMy,
  name: '我的点评',
  meta: {
    keepAlive: true
  }
},
// 病例征集-我的点评活动
{
  path: '/my/myCaseRemark',
  component: caseRemarkActivityMy,
  name: '我的点评'
},
// 病例征集-我的点评活动(适配pc端)
{
  path: '/caseRemarkMy/:activityId',
  component: caseRemarkMy,
  name: '我的点评'
},
// 病例征集-我的点评
{
  path: '/paperRemarkMy/:activityId',
  component: PaperRemarkMy,
  name: '我的点评 '
},
// 病例征集-我的点评
{
  path: '/remarkStart/:activityId/:id',
  component: RemarkStart,
  name: '点评内容',
  meta: {
    allowTouchMove: true
  }
} // 我的点评内容
];