/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2023/3/6 下午5:30
 */

import sensorsV2 from "@/common/js/sensorsV2";
import DataCenterParams from "@/common/js/dataCenter";

/*
* 接收事件推送
* video_action = aplay 自动播放
* video_action = mplay 手动点击播放
* video_action = pause 暂停
* video_action = drag 手动拖拽播放
* video_action = hbeat 心跳上报
*
* 播放 和 暂停时的逻辑
* 进入页面后无任何事件
* 首次点击播放按钮：
*   1.start_play播放事件
*   2.playing播放中开始duration第0秒
* 播放中点击暂停：
*   1.pause_play暂停事件；
*   2.pausing暂停中开始duration第0秒；
*   3.playing播放中上报此次暂停播放的计时到了第几秒（videoCheckNum）
* 第二次点击播放按钮
*   1.start_play播放事件
*   2.playing播放中开始duration第0秒
*   3.pausing暂停中上报此次播放暂停的计时到了第几秒（videoCheckNum）
* */
let DataCenter = null,
  isFirstPlay = 0;
export function pushParams(parmas) {
  // DataCenter duration为0 包含video_start_type 或 video_pause_type
  DataCenter = redundancyDataCenterParams(parmas); // 整合数据
  // 复制出一个携带 播放到第几秒 或者 暂停时是第几秒 video_start_type 和 video_pause_type清空
  let data = JSON.parse(JSON.stringify(DataCenter));
  data.duration = data.videoCheckNum;
  data.video_start_type = null;
  data.video_pause_type = null;
  // 复制出一个携带 duration为0 video_start_type 和 video_pause_type清空
  let data2 = JSON.parse(JSON.stringify(DataCenter));
  data2.video_start_type = null;
  data2.video_pause_type = null;
  switch (DataCenter.video_action) {
    case 'aplay':
      // 开始播放
      playerReport('start_play', DataCenter);
      playerReport('playing', data2);
      if (isFirstPlay > 0) {
        // 除第一次外 其他时候携带 上次暂停位置
        playerReport('pausing', data);
      }
      isFirstPlay++;
      break;
    case 'mplay':
      // 开始播放
      playerReport('start_play', DataCenter);
      playerReport('playing', data2);
      if (isFirstPlay > 0) {
        // 除第一次外 其他时候携带 上次暂停位置
        playerReport('pausing', data);
      }
      isFirstPlay++;
      break;
    case 'hbeat':
      // 播放中
      DataCenter.duration = DataCenterParams.videoWatchAdd.videoCheckNum;
      playerReport('playing');
      break;
    case 'drag':
      // 拉动进度条
      if (DataCenter.type !== '1' && (DataCenter.video_drag_from || DataCenter.video_drag_to)) {
        playerReport('drag_progress_bar');
      }
      break;
    case 'pause':
      if (DataCenter.video_pause_type.indexOf('播放完成') >= 0) {
        // 播放结束 播放停止
        DataCenter.video_stop_type = DataCenter.video_pause_type;
        DataCenter.video_pause_type = null;
        playerReport('stop_play', DataCenter);
        playerReport('pausing', data2);
        playerReport('playing', data);
      } else {
        // 播放暂停
        playerReport('pause_play', DataCenter);
        playerReport('pausing', data2);
        playerReport('playing', data);
      }
      break;
    case 'pausing':
      // 暂停中  页面停留中
      DataCenter.duration = DataCenterParams.videoWatchAdd.videoCheckNum;
      playerReport('pausing');
      break;
  }
  DataCenterParams.videoWatchAdd.videoCheckNum = 0;
}

/*
* 短信点击
* */
export function cdpSmsClick(sendId, cdp_uid) {
  let click_time = new Date();
  let params = {};
  params = {
    cdp_uid: cdp_uid,
    send_id: sendId,
    // 短信下发ID
    click_time: click_time // 点击时间
  };

  sensorsV2.track('sms_click', params);
}

/*
* 课程报名
* */
export function enrollReport(data) {
  if (!data.start_fill_time) data.start_fill_time = data.enter_time;
  sensorsV2.track('enroll', data);
}

/*
* 播放事件统一上报
* type = start_play 开始播放
* type = playing 播放中
* type = drag_progress_bar 拉动进度条
* type = pause_play 暂停播放
* type = pausing 播放暂停中
* type = stop_play 播放停止
* */
function playerReport(type, data) {
  data = data || DataCenter;
  if (type === 'pausing') {
    data.video_action = "pausing";
  }
  if (type === 'playing') {
    data.video_action = "hbeat";
  }
  sensorsV2.track(type, data);
}

/*
* 冗余一下其他版本字段
* */
function redundancyDataCenterParams(params) {
  let data = JSON.parse(JSON.stringify(params));
  data.type = data.type.toString();
  data.media_type = data.program_type === '0' ? '视频' : '音频'; // // 媒体类型 0:视频; 1:音频
  data.course_type = data.type === '1' ? '直播' : data.type === '2' ? '点播' : '回放'; // // 课程会议类型	1.直播 2点播 3回放；
  data.course_id = data.contentId; // 课程会议id
  data.video_position = data.position; // 当前播放位置
  data.video_position_before = data.video_drag_from; // 进度条拖动前位置
  data.video_position_after = data.video_drag_to; // 进度条拖动后位置
  // if (data.Completed) data.play_id = uuid() // 每次点击播放 重新生成播放id
  // 用于记录视频如何启动播放，枚举值包括：从头自动播放、手工点击播放、断点续播
  switch (data.video_action) {
    // case 'hbeat':
    //     data.video_start_type = '自动播放'
    //     break
    case 'mplay':
      data.video_start_type = '手工点击播放';
      break;
    default:
      data.video_start_type = null;
  }
  if (data.automaticOrientation && data.type !== '1') {
    data.video_start_type = '断点续播';
    DataCenterParams.videoWatchAdd.automaticOrientation = false;
  }
  return data;
}