/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2024/5/28 下午6:08
 */

/* eslint-disable */
import http from '@/common/js/http';
import router from '../../router';
import store from '../../store/index';
import fetch from '@/common/js/fetch';
import { Api } from '@/api/api';
import microApp from "@micro-zoe/micro-app";
import { pushParams } from "@/common/js/sensorsV2/incidentReported";
import { inviteVideoTasks } from "@/common/js/inviteTasks/inviteTasks";
const Base64 = require('js-base64').Base64;
let companyInfo = {};
setTimeout(() => {
  companyInfo = store.getters;
}, 500);
let currentRouter = '',
  resetUuId = 0;
let tag = {
  checkNum: 0,
  isCheck: true // 是否需要继续上报
};

/*
* 按照缓存时间长久  清除本地缓存
* */
export function clearLocalStorage() {
  let curTime = new Date().getTime();
  // 调研问卷表单缓存
  let qForm = JSON.parse(localStorage.getItem('qForm'));
  if (qForm && qForm.createTime) {
    if (curTime - qForm.createTime > 432000000) {
      // 缓存超过5天
      localStorage.removeItem('qForm');
    }
  }
}

// 观看数据上报
export function setCurrentRouter(router) {
  // 当前路由地址
  setTimeout(() => {
    currentRouter = router.fullPath;
  }, 100);
}

/*
* 全局定时器
* */
export function globalInterval() {
  setInterval(() => {
    watchAdd(); // 视频打点
    tagReport(); // 标签打点
  }, 1000);
}

/*
* 全局变量
* */
let DataCenterParams = {
  videoWatchAddDynamic: {
    // 观看状态  事件参数
    isPlay: false,
    // 是否只能在播放中
    isClickPlay: false // 是否点击过播放按钮
  },

  videoWatchAdd: {
    uid: null,
    // 用户id
    videoCheckNum: 0,
    userEnroll: 1,
    // 用户身份
    multiple_identity_id: 1,
    // 用户身份
    userEnrollData: 1,
    // 用户身份信息
    businessName: 'saas',
    // 枚举值包括：SAAS、会议、代表工作台、互联网医院  SAAS：saas
    platform: 'h5',
    // 按照终端枚举：ios/android/wechat/h5/web/xcx/maca/wina; saas业务按目前的终端分布只使用到web/h5
    companyId: null,
    // 租户id
    company_id: null,
    // 租户id
    businessId: '',
    // 业务id
    contentId: '',
    // 课程ID
    live_stream_id: null,
    // 直播/点播ID
    Completed: false,
    // true/false   – true 为 用户手动点击播放按钮
    automaticOrientation: false,
    // 本地使用 // true/false   – true 为 继续上次播放，上报一次后需要重置
    file_id: '',
    // 视频id 回放id -- 营销中心
    position: 0,
    // 视频进度条当前的位置秒数。开始时间为0s。是为了记住播放位置用
    type: '',
    // 1.直播 2点播 3回放；
    program_type: '0',
    // 0:视频; 1:音频
    video_player_sdk: 'video',
    // 	播放器sdk识别name_version(H5VP_1.019.2)
    video_player_v: 'video',
    // 播放器版本 同上， 统计第二版
    video_action: 'hbeat',
    // aplay\mplay\pause\drag\hbeat  自动播放、手动点击播放、暂停、手动拖拽播放、心跳上报；配合position识别从头还是断点续播；心跳上报每5s一次
    video_drag_from: null,
    // 视频物理位置，配合action=drag；action非drag不传此参数
    video_drag_to: null,
    // 视频物理位置，配合action=drag；action非drag不传此参数
    video_pause_type: null,
    // 枚举值包括：手工点击暂停、网络状况不佳、失去视区焦点、非当前页状态、非当前APP状态、插播广告 旧：1手动暂停2系统暂停；action非pause不传此参数
    video_userId: null,
    // 观看视频的用户登录后的id
    video_userPageUniq: new Date().getTime().toString(),
    // 在用户进入视频播放页，生成一个唯一识别码，用来串联页面和视频上报的日志；页面日志和所有视频上报都携带这个码；用户再次进入时重新生成；
    play_id: '',
    // 播放ID 本次播放行为（用户对单个视频从开始播放事件到停止播放事件）的全局唯一ID
    duration: 0 // 该持续性事件的本次持续时长。
  }
};

export default DataCenterParams;

/*
* 通知子应用 视频的播放状态
* */
export function videoChange() {
  microApp.setData('activity', {
    type: 'videoChange',
    time: new Date().getTime(),
    data: DataCenterParams.videoWatchAddDynamic
  });
  microApp.setData('task', {
    type: 'videoChange',
    time: new Date().getTime(),
    data: DataCenterParams.videoWatchAddDynamic
  });
}

/*
* 通知子应用 课件上传页数配置（启用页数审核、最小页数）
* */
export function distributePagesNotify(entries) {
  microApp.setData('activity', {
    type: 'coursewarePagesConfig',
    time: new Date().getTime(),
    data: entries
  });
}
export function eventAdd(item) {
  // 视频事件上报
  videoChange();
  let params = JSON.stringify(DataCenterParams.videoWatchAdd);
  params = JSON.parse(params);
  for (let key in item) {
    params[key] = item[key];
  }
  reportData(params);
}

/*
* 5秒心跳上报
* */
function watchAdd() {
  if (routerIsDetails()) {
    // 是否在播放页面
    resetUuId = 0;
    if (DataCenterParams.videoWatchAddDynamic.isPlay) {
      // 是否正在播放
      DataCenterParams.videoWatchAdd.videoCheckNum++;
      if (DataCenterParams.videoWatchAdd.videoCheckNum > 4) {
        // 视频事件上报
        let params = DataCenterParams.videoWatchAdd;
        params.video_action = 'hbeat';
        reportData(params);
        DataCenterParams.videoWatchAdd.videoCheckNum = 0;
        // 视频浏览任务 2025 在第一次上报观看数据时 上报一次观看任务数据
        inviteVideoTasks(DataCenterParams);
      }
    } else {
      // 神策2.0 暂停也需要上报 // 执行过播放开始计时上报
      if (!DataCenterParams.videoWatchAddDynamic.isClickPlay) return;
      DataCenterParams.videoWatchAdd.videoCheckNum++;
      if (DataCenterParams.videoWatchAdd.videoCheckNum > 4) {
        // 视频事件上报
        let params = DataCenterParams.videoWatchAdd;
        params.video_action = 'pausing';
        sensorsV2Track(params);
        DataCenterParams.videoWatchAdd.videoCheckNum = 0;
      }
    }
  } else {
    // 页面离开后重置 uuid
    if (!resetUuId) {
      resetUuId = 1;
    }
    // 离开播放页面重置状态
    DataCenterParams.videoWatchAddDynamic.isPlay = false;
    DataCenterParams.videoWatchAdd.videoCheckNum = 0;
  }
}

// 标签上报
function tagReport() {
  // routerIsDetails() 是否是在播放器页面
  // routerIsArticle() 是否是在文章页面
  // tag.isCheck 接口返回是否需要继续上报
  let _routerIsDetails = routerIsDetails();
  let _routerIsArticle = routerIsArticle();
  let activityType = _routerIsDetails ? 'video' : 'cms';
  let shouldReport = tag.isCheck && (_routerIsDetails || _routerIsArticle);
  if (shouldReport) {
    if (DataCenterParams.videoWatchAddDynamic.isPlay || _routerIsArticle) {
      // 是否正在播放 or 是否正在浏览文章
      tag.checkNum++;
      if (tag.checkNum === 30) {
        tag.checkNum = 0;
        // 标签事件上报
        reportTagData(activityType);
      }
    }
  } else {
    // 正在浏览文章页面，且接口返回不需要打标签时，不重置
    if (_routerIsArticle && !tag.isCheck) {
      return false;
    }
    // 离开页面恢复默认值
    tag.checkNum = 0;
    tag.isCheck = true;
  }
}

// 标签数据上报
function reportTagData(val) {
  let busId = val === 'video' ? DataCenterParams.videoWatchAdd.contentId || router.app['_route'].params.id : router.app['_route'].params.id;
  // code 251  继续轮巡
  fetch.post(Api.video.labelPointNew, {
    activityId: busId,
    activityType: val
  }).then(res => {
    if (res.code === 0) {
      // 0 表示不需要打标签
      tag.isCheck = false;
    }
  }).catch(err => {
    if (err.code !== 251) {
      tag.isCheck = false;
    }
  });
}

// 是否在播放页面
function routerIsDetails() {
  let videoCorrect = false;
  let addRouter = '/details';
  for (let item of addRouter.split('/')) {
    item = '/' + item + '/';
    if (currentRouter.indexOf(item) >= 0) {
      videoCorrect = true;
    }
  }
  return videoCorrect;
}

// 是否在文章页面
function routerIsArticle() {
  let articleCorrect = false;
  let addRouter = '/article';
  for (let item of addRouter.split('/')) {
    item = '/' + item + '/';
    if (currentRouter.indexOf(item) >= 0) {
      articleCorrect = true;
    }
  }
  return articleCorrect;
}
function reportData(params) {
  if (!params.companyId) {
    params.companyId = companyInfo.companyId;
    params.company_id = companyInfo.companyId;
    params.video_userId = companyInfo.userId;
    params.uid = companyInfo.userId;
  }
  let query = '?';
  for (let key in params) {
    if (params[key] !== null) {
      query += `${key}=${params[key]}&`;
    }
  }
  query = query.slice(0, query.length - 1);
  /*上报给saas*/
  let href = window.location.href;
  let saasKey = '554e50dff5d50848f41c14f5bd3de94c'; // 生产
  if (href.indexOf('localhost') > 0) {
    saasKey = '41a0483432982e7419723243e108e12f'; // 如果本地调试
  } else if (href.indexOf('.test.') > 0) {
    saasKey = '41a0483432982e7419723243e108e12f'; // 开发
  } else if (href.indexOf('.100live.') > 0 || href.indexOf('saas-test.') >= 0) {
    saasKey = '98986a243eb0b8c96245f18468da9881'; // 测试
  }

  new Image().src = `https://dotting.cmalive.cn/${saasKey}/wow.gif${query}`;
  /*上报给大数据*/
  new Image().src = `https://vs.cmalive.cn/va.gif${query}`;
  /*
   * 神策上报第二版
   * */
  sensorsV2Track(params);
  requestLog({
    config: {
      debugLog: true,
      url: 'https://dotting.cmalive.cn'
    },
    data: query
  });
}

// END
/*
* 神策上报第二版
* */
function sensorsV2Track(params) {
  pushParams(params);
}

/*
* 数据中心接入视频统计
* */
export function dataCenterVideoWatch(businessId, contentId, type, isCompleted, position) {
  const userId = companyInfo.userId;
  const companyId = companyInfo.companyId;
  new Image().src = `http://vs.cmalive.cn/va.gif?companyId=${companyId}&userId=${userId}&businessId=${businessId}&contentId=${contentId}&type=${type}&isCompleted=${isCompleted}&position=${position}`;
}

/*
* 用户行为日志 （node）
* */
export function requestLog(log) {
  const {
    config,
    data,
    status
  } = log;
  if (config.debugLog) {
    // 验证是否需要日志
    const routeData = router.app._route; // 获取路由信息
    let origins = routeData.path;
    for (let key in routeData.params) {
      let keyVal = '/' + routeData.params[key];
      origins = origins.split(keyVal).join('') || 'keyValNull';
    }
    origins = origins.substr(1); // origins格式化后 用作于日志输出目录
    const params = {
      projectName: 'frontFace',
      AnonymousId: localStorage.getItem('AnonymousId'),
      // 匿名ID
      userId: companyInfo.userId,
      companyId: companyInfo.companyId,
      config: config,
      Response: data,
      httpStatus: status
    };
    // new Image().src = `https://err.100tt.com.cn/log/ajax.gif?origins=${origins}&data=wei${Base64.encode(encodeURIComponent(JSON.stringify(params)))}`
  }
}

export function AssistMiniProgram(to, from, token) {
  /*
  *  TODO 小程序埋点
  * */
  let TAG = 'REPRESENTATIVEWORKBENCH';
  let TAG2 = 'REPRESENTATIVEVISIT';
  let needToken = true;
  let href = window.location.href;
  setTimeout(() => {
    if (href.indexOf(TAG2) >= 0) {
      needToken = false;
    }
    if (href.indexOf(TAG) >= 0) {
      pushAssistMiniProgram(token, 'enter', needToken);
    }
  }, 500);
}
function pushAssistMiniProgram(token, type, isToken) {
  let host = 'https://npro.100url.cn'; // 线上API地址
  const href = window.location.href;
  let referer = href;
  if (href.indexOf('localhost') > 0 || window.location.port.indexOf('600') >= 0) {
    host = ''; // 本地调试走代理
  } else if (href.indexOf('.test.') > 0) {
    host = 'https://npro.dev.100url.cn';
  } else if (href.indexOf('.100live.') > 0 || href.indexOf('saas-test.') >= 0) {
    host = 'https://npro.test.100url.cn';
  }
  if (window.location.href.indexOf('redirect_path') >= 0) {
    referer = href.split('redirect_path=')[1];
    referer = window.location.origin + '/#' + decodeURIComponent(referer);
  }
  if (!isToken || isToken && token) {
    let params = {
      referer: referer,
      authorization: token,
      type: type
    };
    http.post(`${host}/workbench/adminapi/click/saas`, params).then(res => {
      let {
        code
      } = res;
      if (code === 0) {
        localStorage.setItem('AssistMiniProgramMsg', 'SUCCESS');
      }
    });
  }
}

// 邀请活动缓存数据
export const setGlobalInviteCore = toPathInfo => {
  const inviteId = toPathInfo.query.invId;
  if (!inviteId) return;
  const splitPath = toPathInfo.path.split('/');
  localStorage.setItem('inviteId', inviteId);
  localStorage.setItem('taskVideoId', splitPath.pop());
};