/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.unshift.js";
/*
 * Sun-Sky  2023/4/10 下午1:31
 */

import fetch from 'common/js/fetch';
import { Api } from '@/api/api';
const message = {
  state: {
    business: 15,
    // 消息中心业务号
    postMsgList: [],
    // 互动交流帖子消息
    postMsgTotal: 0,
    showReadBtn: false,
    // 是否展示【全部已读】按钮
    unReadMsgCount: 0 // 未读消息数量
  },

  mutations: {
    INIT_POST_MSG_LIST(state) {
      state.postMsgList = [];
    },
    SET_POST_MSG_LIST(state, postMsgList) {
      state.postMsgList = state.postMsgList.concat(postMsgList);
    },
    CONCAT_POST_MSG_LIST(state, socketMsg) {
      state.postMsgList = state.postMsgList.unshift(socketMsg);
    },
    SET_SHOW_READ_BTN(state, showReadBtn) {
      state.showReadBtn = showReadBtn;
    },
    SET_POST_MSG_TOTAL(state, postMsgTotal) {
      state.postMsgTotal = postMsgTotal;
    },
    SET_UNREAD_MSG_COUNT(state, unReadMsgCount) {
      state.unReadMsgCount = unReadMsgCount;
    }
  },
  actions: {
    GetPostMsgList({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        fetch.post(Api.messageV2.getMessageList, data).then(response => {
          let tempData = response.data ? response.data.content : null;
          tempData = tempData.map(item => {
            return {
              id: item.id,
              isRead: item.isRead,
              isTop: item.isTop,
              type: item.type,
              business: item.business,
              ...JSON.parse(item.message)
            };
          });
          commit('SET_POST_MSG_LIST', tempData);
          commit('SET_POST_MSG_TOTAL', response.data.totalElements || null);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    GetUnReadMsgCount({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        fetch.get(`${Api.messageV2.getUnReadCount}`).then(response => {
          // fetch.get(`${Api.messageV2.getUnReadCount}?business=${state.business}`).then(response => {
          const data = response.data;
          commit('SET_UNREAD_MSG_COUNT', data);
          if (data > 0) {
            commit('SET_SHOW_READ_BTN', true);
          } else {
            commit('SET_SHOW_READ_BTN', false);
          }
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    ReadAllMsg({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        fetch.post(`${Api.messageV2.markAllRead}`).then(response => {
          // fetch.post(`${Api.messageV2.markAllRead}?business=${state.business}`).then(response => {
          commit('SET_UNREAD_MSG_COUNT', 0);
          commit('SET_SHOW_READ_BTN', false);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
export default message;