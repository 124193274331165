/* this file is transformed by vux-loader */
/* eslint-disable */
import fetch from 'common/js/fetch';
import { Api } from '@/api/api';

/*
* 视频浏览任务上报 2025 版
* */
export function inviteVideoTasks(data) {
  let inviteId = localStorage.getItem('inviteId');
  let inviteBusId = localStorage.getItem('taskVideoId');
  let {
    contentId
  } = data.videoWatchAdd; // 课程id
  if (inviteId) {
    if (inviteBusId === contentId) {
      fetch.post(Api.common.inviteUserRelation, {
        type: 'video',
        invId: inviteId,
        busId: inviteBusId
      }).then(res => {
        if (res.code === 0) {
          localStorage.removeItem('inviteId');
          localStorage.removeItem('taskVideoId');
        }
      });
    }
  }
}