/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
/*
 * Sun-Sky  2024/3/26 上午11:07
 */
import microApp from '@micro-zoe/micro-app';
import router from '../../router';
import store from "@/store";
const href = window.location.href;
const origin = window.location.origin;
let microAppConfig = {
  domain: 'http://localhost:6002',
  // 本地启动的microApp
  domainMetting: 'http://localhost:6008',
  // 本地启动的microApp - metting
  domainTask: 'http://localhost:6004' // 本地启动的microApp - task
};
// 非本地环境microApp行走当前域
if (href.indexOf('localhost') < 0 && window.location.port.indexOf('600') < 0) {
  microAppConfig.domain = origin + '/activity';
  microAppConfig.domainMetting = origin + '/meeting-web';
  microAppConfig.domainTask = origin + '/task';
}
/*
* 监听子应用 数据通讯
* */
microApp.addDataListener('activity', data => {
  // TODO 注意子应用 发送的key要遵循使用String类型 不要使用bool类型
  console.log('接收到【activity】基座消息', data);
  listenerToken(data); // token异常通知
  listenerPath(data); // 基座应用 路由跳转通知
});
/*
* 监听子应用 数据通讯
* */
microApp.addDataListener('metting', data => {
  // TODO 注意子应用 发送的key要遵循使用String类型 不要使用bool类型
  console.log('接收到【metting】基座消息', data);
  listenerToken(data); // token异常通知
  listenerPath(data); // 基座应用 路由跳转通知
});
/*
* 监听子应用 话题pk
* */
microApp.addDataListener('topicPk', data => {
  // TODO 注意子应用 发送的key要遵循使用String类型 不要使用bool类型
  console.log('接收到【topicPk】基座消息', data);
  listenerToken(data); // token异常通知
  listenerPath(data); // 基座应用 路由跳转通知
});

microApp.addDataListener('saas-case-collection', data => {
  // TODO 注意子应用 发送的key要遵循使用String类型 不要使用bool类型
  console.log('接收到【saas-case-collection】基座消息', data);
  listenerToken(data); // token异常通知
  listenerPath(data); // 基座应用 路由跳转通知
});
/*
* 监听子应用 签到任务
* */
microApp.addDataListener('task', data => {
  // TODO 注意子应用 发送的key要遵循使用String类型 不要使用bool类型
  console.log('接收到【topicPk】基座消息', data);
  listenerToken(data); // token异常通知
  listenerPath(data); // 基座应用 路由跳转通知
});

/*
* token异常通知
* */
function listenerToken(data) {
  if (data.token === 'tokenError') {
    router.replace('/login');
  }
}

/*
  * 基座应用 路由跳转通知
  *
  * 后退path 传入back
  * 如果需要open 或者 replace 需赋值给 openMode 例：{path: '/',openMode: 'open'}
  * */
function listenerPath(data) {
  if (data.path) {
    if (data.path === 'back') {
      let routerList = store.getters.routerFootprint;
      let str = '/findPassword,/register,/login,/smsLogin,/improveInformation';
      let stop = false;
      for (let i in routerList) {
        if (stop) return;
        if (str.indexOf(routerList[i]) === -1) {
          if (window.location.href.indexOf(routerList[i]) < 0) {
            stop = true;
            router.push({
              path: routerList[i]
            });
          }
        } else {
          stop = true;
          router.push({
            path: '/'
          });
        }
        // 到最后一项历史记录还没有匹配到的话
        if (!stop && parseInt(i) === routerList.length - 1) {
          router.push({
            path: '/'
          });
        }
      }
    } else if (data.path === -1) {
      router.go(-1);
    } else {
      switch (data.openMode) {
        case 'open':
          window.open(`${origin}/#${data.path}`);
          break;
        case 'replace':
          router.replace({
            path: data.path,
            query: data.query || null
          });
          break;
        default:
          router.push({
            path: data.path,
            query: data.query || null
          });
      }
    }
  }
}
export default microAppConfig;