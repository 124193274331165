/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    },
    on: {
      "touchmove": _vm.onTouchMove
    }
  }, [_vm.showHeader && !_vm.isApp ? _c('m-weixin') : _vm._e(), _c('m-header', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showHeader && !_vm.isApp,
      expression: "showHeader && !isApp"
    }]
  }), _c('keep-alive', [_vm.$route.meta.keepAlive ? _c('router-view') : _vm._e()], 1), !_vm.$route.meta.keepAlive && _vm.isRouterAlive ? _c('router-view') : _vm._e(), _c('my-footer'), _c('loading', {
    model: {
      value: _vm.isLoading,
      callback: function ($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };