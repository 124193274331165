/* this file is transformed by vux-loader */
/* eslint-disable */
import { getToken, removeToken, setToken, tokenToApp } from 'common/js/util';
import fetch from 'common/js/fetch';
import { Api } from '@/api/api';
import store from '@/store';
const user = {
  state: {
    token: '',
    userInfo: {},
    userId: '',
    nickname: '',
    userName: '',
    hosPital: '',
    userTitle: '',
    userOffice: '',
    phone: '',
    credentials: '',
    // 证件照
    sex: '',
    avatar: '',
    description: '',
    roles: [],
    messageList: [],
    bagPmi: false,
    uploadPmi: false,
    livePmi: false,
    surveyPmi: false,
    downloadPmi: false,
    openid: '',
    isUserInfoCompleted: false
  },
  mutations: {
    SET_BAG: (state, bagPmi) => {
      state.bagPmi = bagPmi;
    },
    SET_UPLOAD: (state, uploadPmi) => {
      state.uploadPmi = uploadPmi;
    },
    SET_LIVE: (state, livePmi) => {
      state.livePmi = livePmi;
    },
    SET_SURVEY: (state, surveyPmi) => {
      state.surveyPmi = surveyPmi;
    },
    SET_DOWNLOAD: (state, downloadPmi) => {
      state.downloadPmi = downloadPmi;
    },
    SET_DESC: (state, description) => {
      state.description = description;
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone;
    },
    SET_CREDENTIALS: (state, credentials) => {
      state.credentials = credentials;
    },
    SET_MESSAGE: (state, list) => {
      state.messageList = list;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId;
    },
    SET_NAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_USERNAME: (state, userName) => {
      state.userName = userName;
    },
    SET_HOSPITAL: (state, hosPital) => {
      state.hosPital = hosPital;
    },
    SET_TITLE: (state, userTitle) => {
      state.userTitle = userTitle;
    },
    SET_OFFICE: (state, userOffice) => {
      state.userOffice = userOffice;
    },
    SET_SEX: (state, sex) => {
      state.sex = sex;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_OPENID: (state, openid) => {
      state.openid = openid;
    },
    SET_USER_INFO_COMPLETE: (state, status) => {
      state.isUserInfoCompleted = status;
    }
  },
  actions: {
    // 登录 这个暂时不用
    LoginByPassword({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        fetch.post(Api.common.login, userInfo).then(response => {
          const data = response.data;
          setToken(data);
          tokenToApp(data);
          commit('SET_TOKEN', data);
          /* 登录后清除用户信息 */
          commit('SET_USER_INFO', {});
          commit('SET_USER_ID', '');
          commit('SET_ROLES', []);
          commit('SET_MESSAGE', []);
          commit('SET_AVATAR', null);
          commit('SET_NAME', '');
          commit('SET_USERNAME', '');
          commit('SET_HOSPITAL', '');
          commit('SET_TITLE', '');
          commit('SET_OFFICE', '');
          commit('SET_SEX', '');
          commit('SET_PHONE', '');
          commit('SET_CREDENTIALS', '');
          commit('SET_DESC', '');
          /* END */
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 验证码 登录
    LoginBySms({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        fetch.post(Api.common.loginBySms, userInfo).then(response => {
          const data = response.data;
          setToken(data);
          tokenToApp(data);
          commit('SET_TOKEN', data);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 微信 登录
    LoginByWx({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        fetch.post(Api.common.wxloginbycode, userInfo).then(response => {
          const data = response.data;
          setToken(data);
          tokenToApp(data);
          commit('SET_TOKEN', data);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        fetch.get(Api.userApi.getUserApi, {}).then(response => {
          const data = response.data;
          if (!data.photo) {
            data.photo = 'https://yibaiserver-1252497236.cos.ap-beijing-1.myqcloud.com/yibai/default_user_avatar.png';
          } else if (data.photo.includes('default_user_avatar.png')) {
            data.photo = 'https://yibaiserver-1252497236.cos.ap-beijing-1.myqcloud.com/yibai/default_user_avatar.png';
          }
          commit('SET_USER_INFO', data);
          commit('SET_USER_ID', data.userId);
          commit('SET_ROLES', data.role);
          commit('SET_NAME', data.nickname);
          commit('SET_USERNAME', data.username);
          commit('SET_HOSPITAL', data.hospital);
          commit('SET_TITLE', data.title);
          commit('SET_OFFICE', data.office);
          commit('SET_SEX', data.sex);
          commit('SET_AVATAR', data.photo);
          commit('SET_PHONE', data.phone);
          commit('SET_CREDENTIALS', data.credentials || null);
          commit('SET_DESC', data.description);
          commit('SET_OPENID', data.openid);
          commit('SET_USER_INFO_COMPLETE', data.completeUserInfo ?? false);
          store.dispatch('GetTags');
          resolve(data.completeUserInfo ?? false);
        }).catch(error => {
          removeToken();
          reject(error);
        });
      });
    },
    ClearUserInfo({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_USER_INFO', {});
        commit('SET_USER_ID', '');
        commit('SET_ROLES', []);
        commit('SET_MESSAGE', []);
        commit('SET_AVATAR', null);
        commit('SET_NAME', '');
        commit('SET_USERNAME', '');
        commit('SET_HOSPITAL', '');
        commit('SET_TITLE', '');
        commit('SET_OFFICE', '');
        commit('SET_SEX', '');
        commit('SET_PHONE', '');
        commit('SET_CREDENTIALS', '');
        commit('SET_DESC', '');
        commit('SET_OPENID', '');
        removeToken();
        resolve();
      });
    },
    // 登出
    LogOut({
      commit
    }) {
      return new Promise((resolve, reject) => {
        fetch.get(Api.common.logout, {}).then(() => {
          commit('SET_TOKEN', '');
          commit('SET_USER_INFO', {});
          commit('SET_USER_ID', '');
          commit('SET_ROLES', []);
          commit('SET_MESSAGE', []);
          commit('SET_AVATAR', null);
          commit('SET_NAME', '');
          commit('SET_USERNAME', '');
          commit('SET_HOSPITAL', '');
          commit('SET_TITLE', '');
          commit('SET_OFFICE', '');
          commit('SET_SEX', '');
          commit('SET_PHONE', '');
          commit('SET_CREDENTIALS', '');
          commit('SET_DESC', '');
          commit('SET_OPENID', '');
          removeToken();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    // PC端登录
    LoginByPc({
      commit
    }, val) {
      return new Promise(resolve => {
        commit('SET_TOKEN', val);
        setToken(val);
        resolve();
      });
    },
    // PC端登录
    SetToken({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', getToken());
        resolve();
      });
    },
    // 获取用户身份权限信息
    GetTags({
      commit
    }) {
      fetch.post(Api.common.getTags, {}).then(response => {
        if (response.data.length > 0) {
          /* eslint-disable */
          let str = JSON.stringify(response.data);
          str.indexOf('上传视频') !== -1 ? commit('SET_UPLOAD', true) : '';
          str.indexOf('发起直播') !== -1 ? commit('SET_DOWNLOAD', true) : '';
          str.indexOf('发起调查问卷') !== -1 ? commit('SET_SURVEY', true) : '';
          str.indexOf('创建直播') !== -1 ? commit('SET_LIVE', true) : '';
          str.indexOf('百福袋') !== -1 ? commit('SET_BAG', true) : '';
          /* eslint-enable */
        }
      }).catch(error => {
        console.log('infoerror', error);
      });
    },
    GetMessage({
      commit
    }) {
      return new Promise((resolve, reject) => {
        fetch.post(Api.common.getMessageList, {
          pageSize: 100,
          pageNum: 0
        }).then(response => {
          if (response.code === 0) {
            if (response.data.list.length > 4) {
              commit('SET_MESSAGE', response.data.list.slice(response.data.list.length - 4));
            } else {
              commit('SET_MESSAGE', response.data.list);
            }
            resolve();
          }
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
export default user;