/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
const video = {
  state: {
    relevanceActivity: [] // 可以统一的关联活动
  },

  mutations: {
    CLEAR_RELEVANCE_ACTIVITY: state => {
      // 清空活动图标
      state.relevanceActivity = [];
    },
    UPDATE_RELEVANCE_ACTIVITY: (state, obj) => {
      // 更新活动图标
      for (let i in state.relevanceActivity) {
        if (state.relevanceActivity[i].id === obj.id) {
          state.relevanceActivity.splice(i, 1, obj);
        }
      }
    },
    ADD_RELEVANCE_ACTIVITY: (state, obj) => {
      // 增加活动图标
      let isPush = true;
      state.relevanceActivity.forEach(list => {
        if (list.id === obj.id) isPush = false;
      });
      if (isPush) state.relevanceActivity.push(obj);
      // 排序
      state.relevanceActivity.sort(function (a, b) {
        return a.id - b.id;
      });
    },
    DELETE_RELEVANCE_ACTIVITY: (state, obj) => {
      // 删除活动图标
      for (let index in state.relevanceActivity) {
        if (state.relevanceActivity[index].id === obj.id) {
          state.relevanceActivity.splice(index, 1);
        }
      }
      // 排序
      state.relevanceActivity.sort(function (a, b) {
        return a.id - b.id;
      });
    },
    SET_RELEVANCE_ACTIVITY: (state, obj) => {
      // 创建活动图标
      let json = state.relevanceActivity;
      json = json.concat(obj);
      let newJson = [];
      for (let item1 of json) {
        let flag = true;
        for (let item2 of newJson) {
          if (item1.id === item2.id) {
            flag = false;
          }
        }
        if (flag) {
          // 判断是否重复
          newJson.push(item1); // 不重复的放入新数组。  新数组的内容会继续进行上边的循环。
        }
      }

      json.sort(function (a, b) {
        return a.id - b.id;
      });
      state.relevanceActivity = newJson;
    }
  },
  actions: {
    setRelevanceActivity({
      commit
    }, data) {
      commit('SET_RELEVANCE_ACTIVITY', data);
    }
  }
};
export default video;