/* this file is transformed by vux-loader */
/* eslint-disable */
import { Api } from '@/api/api';
import fetch from 'common/js/fetch';
import { isWeixin, getParameter } from 'common/js/util';
export async function getPayOpenid(obj) {
  try {
    if (!isWeixin() || !obj.pay) {
      throw new Error(!obj.pay ? 'NO pay' : 'NO wx');
    }
    let _getWxAppIdRes = await fetch.get(Api.pay.getWxAppId);
    let appid;
    if (_getWxAppIdRes.code === 0) {
      appid = _getWxAppIdRes.data;
      sessionStorage.setItem('payAppID', appid);
    } else {
      throw new Error('NO appId');
    }
    let _payOpenId = localStorage.getItem('payOpenId_' + appid);
    if (_payOpenId) {
      return _payOpenId;
    }
    let payState = 'PAYGETCODE';
    let wxState = getParameter('state');
    let wxCode = getParameter('code');
    if (wxState === payState && wxCode) {
      // 接受
      let res = await fetch.post(Api.pay.getOpenId, {
        code: wxCode,
        companyId: obj.id
      });
      if (res.code === 0) {
        localStorage.setItem('payOpenId_' + appid, res.data);
        let _redirectUrl = localStorage.getItem('redirectUrl') || window.location.origin;
        localStorage.removeItem('redirectUrl');
        window.location.replace(_redirectUrl);
        return;
      } else {
        throw new Error(res.message);
      }
    }
    if (!wxCode) {
      // 发起
      let redirectUrl = encodeURI(window.location.origin);
      localStorage.setItem('redirectUrl', window.location.href);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&response_type=code&scope=snsapi_base&redirect_uri=${redirectUrl}&state=${payState}#wechat_redirect`;
    }
  } catch (err) {
    return err;
  }
}