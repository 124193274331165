/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2024/3/26 上午11:07
 */

import axios from 'axios';
import store from '@/store';
import { getToken, isWeixinXcx } from 'common/js/util';
import { requestLog } from 'common/js/dataCenter';
import router from '@/router';
import Vue from 'vue';
import { locationHost } from '@/common/js/locationHost';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API,
  // api的base_url
  timeout: 60000,
  // 请求超时时间
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Remote-Host': locationHost,
    'Content-Type': 'application/json; charset=UTF-8',
    'Accept': 'application/json'
  }
});

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Authtype'] = 'webc';
  config.headers['Authorization'] = getToken();
  if (!getToken() && store.getters.token) {
    config.headers['Authorization'] = store.getters.token;
  }
  return config;
}, error => {
  Promise.reject(error);
});

// respone拦截器
service.interceptors.response.use(response => {
  requestLog(response); // 输出日志
  let result = response.data;
  if (result.code === 0 || result.code === 200) {
    return result;
  }
  switch (result.code) {
    case 211:
      if (getToken()) {
        store.dispatch('ClearUserInfo');
      }
      result = null;
      if (isWeixinXcx()) {
        router.replace('/login');
      } else {
        router.replace('/login');
      }
      break;
    case 401:
      alert('该域名尚未注册');
      break;
    case 501:
      window.open('/#/404', '_self');
      break;
  }
  return Promise.reject(result);
}, error => {
  if (error.message.indexOf('60000ms') !== -1) {
    Vue.$vux.toast.text('请求超时，请检查网络或尝试刷新页面');
  } else if (error.message.indexOf('code 502') !== -1) {
    Vue.$vux.toast.text('服务器升级中，请稍后重试');
  } else {
    if (error.message) {
      console.error(error.message);
    } else {
      Vue.$vux.toast.text('后台数据错误');
    }
  }
  return Promise.reject(error);
});
export default {
  post(url, data, debugLog) {
    return service({
      method: 'post',
      url,
      data,
      debugLog: debugLog
    });
  },
  get(url, data, debugLog) {
    return service({
      method: 'get',
      url,
      data,
      debugLog: debugLog
    });
  },
  put(url, data, debugLog) {
    return service({
      method: 'put',
      url,
      data,
      debugLog: debugLog
    });
  },
  update(url, data, debugLog) {
    return service({
      method: 'update',
      url,
      data,
      debugLog: debugLog
    });
  },
  delete(url, data, debugLog) {
    return service({
      method: 'delete',
      url,
      data,
      debugLog: debugLog
    });
  }
};