/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    directives: [{
      name: "transfer-dom",
      rawName: "v-transfer-dom"
    }]
  }, [_c('x-dialog', {
    model: {
      value: _vm.followShowToast,
      callback: function ($$v) {
        _vm.followShowToast = $$v;
      },
      expression: "followShowToast"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "30px"
    }
  }, [_vm.shortUrl ? _c('vue-qr', {
    attrs: {
      "text": _vm.shortUrl,
      "size": 150,
      "margin": 0
    }
  }) : _vm._e(), _c('p', {
    staticClass: "tip"
  }, [_vm._v(_vm._s(_vm.tip))]), !_vm.isfollow ? _c('x-button', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "type": "primary"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.closeToast.apply(null, arguments);
      }
    }
  }, [_vm._v("跳过")]) : _vm._e()], 1)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };