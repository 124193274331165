/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
/*
 * Sun-Sky  2024/8/12 下午2:38
 */

import storage from 'good-storage';
import { Api } from '@/api/api';
import fetch from 'common/js/fetch';
import Fingerprint2 from 'fingerprintjs2';
const base64Js = require('js-base64').Base64;
const sha256Js = require('js-sha256').sha256;

/* eslint-disable */
const TokenKey = 'Yibai-Token';
export function ENV() {
  let ENV = 'prod';
  const href = window.location.href;
  if (href.indexOf('localhost') > 0 || href.indexOf(':8090') > 0) {
    ENV = 'dev';
  } else if (href.indexOf('.test.') > 0) {
    ENV = 'dev';
  } else if (href.indexOf('.100live.') >= 0 || href.indexOf('saas-test.') >= 0) {
    ENV = 'test';
  }
  return ENV;
}
export function getContentTypeByBusinessId(businessId) {
  let contentType;
  switch (parseInt(businessId)) {
    case 1:
      //店员培训
      contentType = 8;
      break;
    case 2:
      //空中课堂
      contentType = 9;
      break;
    case 3:
      //互动公开课
      contentType = 10;
      break;
    case 4:
      //线上公开课
      contentType = 11;
      break;
    case 5:
      //学术会议
      contentType = 12;
      break;
    case 6:
      //积分商城
      contentType = 13;
      break;
    case 8:
      //病例征集
      contentType = 15;
      break;
    case 9:
      //互动课堂
      contentType = 16;
      break;
    case 10:
      //空中课堂Pro
      contentType = 96;
      break;
    case 11:
      //互动课堂Pro
      contentType = 97;
      break;
    default:
      console.log("businessId 错误");
      break;
  }
  return contentType;
  // contentType对应关系表
  // return [
  //     {
  //         contentType: 1,
  //         value: '百福袋'
  //     },
  //     {
  //         contentType: 2,
  //         value: '调查问卷'
  //     },
  //     {
  //         contentType: 3,
  //         value: '抽奖活动'
  //     },
  //     {
  //         contentType: 4,
  //         value: '在线答题'
  //     },
  //     {
  //         contentType: 5,
  //         value: '知识竞赛'
  //     },
  //     {
  //         contentType: 6,
  //         value: '病例征集(废)'
  //     },
  //     {
  //         contentType: 7,
  //         value: '投票活动'
  //     },
  //     {
  //         contentType: 8,
  //         value: '店员培训'
  //     },
  //     {
  //         contentType: 9,
  //         value: '空中课堂'
  //     },
  //     {
  //         contentType: 10,
  //         value: '互动公开课'
  //     },
  //     {
  //         contentType: 11,
  //         value: '线上公开课'
  //     },
  //     {
  //         contentType: 12,
  //         value: '学术会议'
  //     },
  //     {
  //         contentType: 13,
  //         value: '积分商城'
  //     },
  //     {
  //         contentType: 14,
  //         value: '(旧)投票活动'
  //     },
  //     {
  //         contentType: 15,
  //         value: '病例征集'
  //     },
  //     {
  //         contentType: 16,
  //         value: '互动课堂'
  //     },
  //     {
  //         contentType: 17,
  //         value: 'CMS文章'
  //     },
  //     {
  //         contentType: 18,
  //         value: '病例投票'
  //     },
  //     {
  //         contentType: 19,
  //         value: '抽奖活动'
  //     },
  //     {
  //         contentType: 20,
  //         value: '投票活动'
  //     },
  //     {
  //         contentType: 21,
  //         value: '病例投票'
  //     },
  //     {
  //         contentType: 22,
  //         value: '调研问卷3.0'
  //     },
  //     {
  //         contentType: 23,
  //         value: '互动病例'
  //     },
  //     {
  //         contentType: 24,
  //         value: '互动交流'
  //     },
  //     {
  //         contentType: 25,
  //         value: '互动游戏'
  //     },
  //     {
  //         contentType: 26,
  //         value: '投票评比'
  //     },
  //     {
  //         contentType: 27,
  //         value: '投票评比-上传'
  //     },
  //     {
  //         contentType: 28,
  //         value: '线上签约'
  //     },
  //     {
  //         contentType: 29,
  //         value: '动态日历'
  //     },
  //     {
  //         contentType: 30,
  //         value: '论文征集'
  //     },
  //     {
  //         contentType: 35,
  //         value: '话题PK'
  //     },
  //     {
  //         contentType: 36,
  //         value: '病历故事'
  //     },
  //     {
  //         contentType: 96,
  //         value: '空中课堂Pro'
  //     },
  //     {
  //         contentType: 97,
  //         value: '互动课堂Pro'
  //     },
  //     {
  //         contentType: 98,
  //         value: '学术互动'
  //     },
  // ]
}

/*
* 使用webp片转码 压缩图片文件
* */
export function getWebPUrl(url) {
  let webpUrl = '';
  if (url.indexOf('?') >= 0) {
    webpUrl = `${url}&imageMogr2/format/webp`;
  } else {
    webpUrl = `${url}?imageMogr2/format/webp`;
  }
  return webpUrl;
}
let flag = true;

/**
 * 节流
 * @param {function} func 执行函数
 * @param {number} time 防抖节流时间
 */
export function throttle(func, time = 1000) {
  if (flag) {
    flag = false;
    setTimeout(() => flag = true, time);
    return func();
  }
}
export function sha256AndBase64(value) {
  if (!value) return;
  let item = sha256Js(value);
  item = base64Js.encode(item);
  return item;
}

/*
* 通过接口获取 播放唯一 Id
* */
export function getPlayId(callback) {
  fetch.get(Api.dataCenter.getPlayId).then(res => {
    callback({
      playId: res.data
    });
  });
}
export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0,
      v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
export function setAnonymousId() {
  /*
  * 匿名ID
  * */
  Fingerprint2.get(function (components) {
    const values = components.map(function (component, index) {
      if (index === 0) {
        // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
        return component.value.replace(/\bNetType\/\w+\b/, '');
      }
      return component.value;
    });
    // 生成最终id murmur
    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    localStorage.setItem('AnonymousId', murmur);
  });
}
export function getVideoStatus(type) {
  switch (type) {
    case 0:
      return '直播预告';
    case 1:
      return '正在直播';
    case 2:
      return '暂停中';
    case 3:
      return '直播结束';
    case 4:
      return '稍后继续';
    case 5:
      return '正在转码';
    case 6:
      return '转码失败';
    case 7:
      return '未发布';
    case 8:
      return '点播';
    case 9:
      return '直播回放';
    case 11:
      return '尚未开始';
    case 12:
      return '即将开始';
    case -1:
      return '已失效';
  }
}
export function getToken() {
  return storage.get(TokenKey);
  // return 'dd093435-8400-4c7e-837f-e5db3c193a0d'
}

export function setToken(token) {
  return storage.set(TokenKey, token);
}
export function removeToken() {
  return storage.remove(TokenKey);
}
export function tokenToApp(token) {
  if (window.appClient && window.appClient.appType) {
    if (window.appClient.appType === 'android') {
      window.interface.transferToken(token);
      //setTitle为android端定义的方法，作用为更改title
    } else if (window.appClient.appType === 'ios') {
      window.webkit.messageHandlers.transferToken.postMessage(token);
      //showMobile为ios端定义的方法
    }
  }
}

export function timeFormat(date, fmt) {
  //author: meizz
  const o = {
    "M+": date.getMonth() + 1,
    //月份
    "d+": date.getDate(),
    //日
    "h+": date.getHours(),
    //小时
    "m+": date.getMinutes(),
    //分
    "s+": date.getSeconds(),
    //秒
    "q+": Math.floor((date.getMonth() + 3) / 3),
    //季度
    "S": date.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return fmt;
}
export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export function formatDate(tDate, fmt) {
  // author: meizz
  const o = {
    'M+': tDate.getMonth() + 1,
    // 月份
    'd+': tDate.getDate(),
    // 日
    'h+': tDate.getHours(),
    // 小时
    'm+': tDate.getMinutes(),
    // 分
    's+': tDate.getSeconds(),
    // 秒
    'q+': Math.floor((tDate.getMonth() + 3) / 3),
    // 季度
    'S': tDate.getMilliseconds() // 毫秒
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (tDate.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }
  return fmt;
}
export function shuffle(arr) {
  let _arr = arr.slice();
  for (let i = 0; i < _arr.length; i++) {
    let j = getRandomInt(0, i);
    let t = _arr[i];
    _arr[i] = _arr[j];
    _arr[j] = t;
  }
  return _arr;
}
export function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
export function goBack() {
  // window.isMini = true
  if (window.history.length <= 1 || window.lastPath === '/login' || window.lastPath === '/smsLogin') {
    this.$router.push('/');
  } else if (window.lastPath.toLowerCase().indexOf('/details') > -1 || window.lastPath.toLowerCase().indexOf('applyorlogin') > -1 || window.lastPath.toLowerCase().indexOf('applyform') > -1 || window.lastPath === '/') {
    this.$router.push('/');
  } else {
    this.$router.back();
    setTimeout(() => {
      if (window.history.state === null) {
        this.$router.push('/');
      }
    }, 100);
  }
}
export function getRightDate(starts, ends) {
  if (starts === undefined && ends === undefined) {
    return '';
  }
  let start = starts ? formatDate(new Date(parseInt(starts)), 'yyyy-MM-dd hh:mm:ss') : '';
  let end = ends ? formatDate(new Date(parseInt(ends)), 'yyyy-MM-dd hh:mm:ss') : '';
  let result = '';
  if (start === null || end === null || start === '' || end === '' || start === undefined || end === undefined) {
    result = '';
    if (start != '' && end == '') {
      return start;
    } else {
      result = '';
    }
  } else {
    let sDate = start.substring(0, 10);
    let eDate = end.substring(0, 10);
    let sTime = start.substring(11, 16);
    let eTime = end.substring(11, 16);
    let nDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss').substring(0, 10);
    if (sDate === nDate && eDate === nDate) {
      result = `今日  ${sTime}-${eTime}`;
    } else if (sDate === eDate) {
      let mon = sDate.substring(5, 10).replace('-', '月');
      mon += '日';
      result = `${mon}   ${sTime}-${eTime}`;
    } else {
      let smon = sDate.substring(5, 10).replace('-', '月');
      smon += '日';
      let emon = eDate.substring(5, 10).replace('-', '月');
      emon += '日';
      result = `${smon} ${sTime}-${emon} ${eTime}`;
    }
  }
  return result;
}
export function getTheme(val, skin) {
  if (val.indexOf(',') > -1) {
    const ary = val.split(',');
    let str = '';
    ary.forEach(item => {
      str += item + skin + ' ';
    });
    return str;
  } else {
    return val + skin;
  }
}
export function contentTypes(type) {
  switch (type) {
    case 1:
      return '百福袋';
    case 2:
      return '调查问卷';
    case 3:
      return '抽奖活动';
    case 4:
      return '在线答题';
    case 5:
      return '知识竞赛';
    case 6:
      return '医学研究';
    case 7:
      return '投票活动';
    case 8:
      return '店员培训';
    case 9:
      return '空中课堂';
    case 10:
      return '互动公开课';
    case 11:
      return '线上公开课';
    case 12:
      return '学术会议';
    case 13:
      return '积分商城';
    case 14:
      return '(旧)投票活动';
    case 15:
      return '医学研究';
    case 16:
      return '互动课堂';
    case 17:
      return 'CMS文章';
    case 18:
      return '病例投票';
    case 19:
      return '抽奖游戏';
    case 20:
      return '(新)投票活动';
    case 21:
      return '资料下载';
    case 22:
      return '调研问卷3.0';
    case 23:
      return '互动病例';
    case 26:
      return '投票评比';
    case 30:
      return '论文征集';
    case 35:
      return '话题PK';
    case 36:
      return '病历故事';
  }
}
export function GetQueryString(url) {
  let obj = {},
    reg = /([^?=&#]+)=([^?=&#]+)/g;
  url.replace(reg, function () {
    const key = arguments[1],
      value = arguments[2];
    obj[key] = value;
  });
  return obj;
}

/*
* 是否是移动终端
* */
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
export function prototypeAddFunction(Vue) {
  Vue.prototype.Api = Api;
  Vue.prototype.$fetch = fetch;
  Vue.prototype.$cosDomain = 'https://saas-material-1252497236.cos.accelerate.myqcloud.com/mobile/'; // 存储通 全球加速地址
  Vue.prototype.getTheme = function (val, skin) {
    if (val.indexOf(',') > -1) {
      const ary = val.split(',');
      let str = '';
      ary.forEach(item => {
        str += item + skin + ' ';
      });
      return str;
    } else {
      return val + skin;
    }
  };
  Date.prototype.Format = function (fmt) {
    const o = {
      "M+": this.getMonth() + 1,
      //月份
      "d+": this.getDate(),
      //日
      "h+": this.getHours(),
      //小时
      "m+": this.getMinutes(),
      //分
      "s+": this.getSeconds(),
      //秒
      "q+": Math.floor((this.getMonth() + 3) / 3),
      //季度
      "S": this.getMilliseconds() //毫秒
    };

    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    return fmt;
  };
}
export function scrollChange(that) {
  //以倒计时为核心
  // v-html 里面图片 视频加载后改变滚动区域高度 重置scroll ，3000*20/1000 = 60秒后放弃。
  let _i = 0;
  let _hei = 0;
  let _in = setInterval(() => {
    _i++;
    try {
      let _newHei = that.$refs.maxContBox.clientHeight;
      // 比较新老高度 ， 有差异则重置Scroll ，存入新高度
      if (_hei !== _newHei) {
        _hei = _newHei;
        that.discList.push(_newHei);
      }
      if (_i > 3000) clearInterval(_in);
    } catch (e) {
      // '组件被切换，倒计时线程没有回收，及时清除！'
      clearInterval(_in);
    }
  }, 20);
}

/*
* 解析Query
* */
export function getUrlQuery(name) {
  let localUrl = window.location.href;
  if (localUrl.indexOf('?') < 0) {
    return false;
  }
  let url = localUrl.split('?')[1];
  if (url.indexOf('&') > 0) {
    url = url.split('&');
  } else {
    url = [localUrl.split('?')[1]];
  }
  name = name || '';
  let nameRes;
  // 获取全部参数及其值
  for (let i = 0; i < url.length; i++) {
    const info = url[i].split('=');
    const obj = {};
    obj[info[0]] = decodeURI(info[1]);
    url[i] = obj;
  }
  // 如果传入一个参数名称，就匹配其值
  if (name) {
    for (let i = 0; i < url.length; i++) {
      for (const key in url[i]) {
        if (key === name) {
          nameRes = url[i][key];
        }
      }
    }
  } else {
    nameRes = url;
  }
  // 返回结果
  return nameRes;
}
export function scrollImgChange(that) {
  //以图片加载为核心
  let imgNodeList = that.$refs.maxContBox.querySelectorAll('img');
  let imgArr = [...imgNodeList];
  imgArr.forEach(item => {
    item.onload = () => {
      that.discList.push({});
    };
  });
  setTimeout(() => {
    // 延时100毫秒先重置一次，防止详情无图片
    that.discList.push({});
  }, 100);
}
export function mobileSystem() {
  let browser = {
    versions: function () {
      const u = navigator.userAgent;
      const u2 = navigator.userAgent.toLowerCase();
      return {
        //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1,
        //IE内核
        presto: u.indexOf('Presto') > -1,
        //opera内核
        webKit: u.indexOf('AppleWebKit') > -1,
        //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
        //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/),
        //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1,
        //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1,
        //是否iPad
        webApp: u.indexOf('Safari') == -1,
        //是否web应该程序，没有头部与底部
        iosv: u.substr(u.indexOf('iPhone OS') + 9, 3),
        weixin: u2.match(/MicroMessenger/i) == "micromessenger",
        taobao: u.indexOf('AliApp(TB') > -1
      };
    }()
  };
  return browser;
}
export function isHeaderHidden() {
  if (sessionStorage.getItem('header') && sessionStorage.getItem('header') === 'hidden') {
    return false;
  } else {
    return true;
  }
}
/*
* 是否是在IOS
* */
export function isIos() {
  return /iPhone|iPad|iPod|iOS/i.test(navigator.userAgent);
}

/*
* 是否是在微信中
* */
export function isWeixin() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}

/*
* 是否是在微信小程序中
* */
export function isWeixinXcx() {
  return /miniProgram/i.test(window.navigator.userAgent);
}
export function getParameter(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  }
  return null;
}

// 手机号验证
export function phoneverification(code) {
  let reg = /^(0|86|17951)?(13[0-9]|16[567]|15[0-9]|17[0-9]|18[0-9]|19[0-9]|14[5-9])[0-9]{8}$/;
  return reg.test(code);
}
export function formatSexField(sex) {
  let sexTypeObj = {
    '男': 'man',
    'man': 'man',
    '女': 'woman',
    'woman': 'woman',
    'default': ''
  };
  return sexTypeObj[sex] || sexTypeObj.default;
}
export const IDENTITY_CODE = {
  'DOCTOR': 1,
  // 医生
  'PHARMACY_STAFF': 2,
  // 药店人员
  'ENTERPRISE_STAFF': 3,
  // 企业人员
  'PATIENT': 4,
  // 患者
  'VISITOR': 5,
  // 游客
  'NURSE': 6,
  // 护士
  'PHARMACIST': 7,
  // 药师
  'TECHNICIAN': 8 // 技师
};