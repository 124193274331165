/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "yb-view-header"
    }
  }, [_vm.newWebsite ? [_c('div', {
    staticClass: "m-header"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowBack || _vm.isBackView,
      expression: "isShowBack || isBackView"
    }],
    staticClass: "mine-back",
    on: {
      "click": _vm.closeView
    }
  }, [_c('i', {
    staticClass: "icon-back"
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.companyConfig.mobileStyle !== '2' && !_vm.isShowNav && !_vm.isBackView,
      expression: "companyConfig.mobileStyle !== '2' && !isShowNav && !isBackView"
    }],
    staticClass: "mine",
    on: {
      "click": _vm.openNav
    }
  }, [_c('i', {
    staticClass: "icon-menu"
  })]), _c('router-link', {
    staticClass: "info-wrapper",
    attrs: {
      "tag": "div",
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.companyLogoUrl,
      "alt": ""
    }
  })]), _c('router-link', {
    staticClass: "mine",
    attrs: {
      "tag": "div",
      "to": "/user"
    }
  }, [_c('img', {
    attrs: {
      "src": !_vm.avatar ? _vm.defaultAva : _vm.avatar
    }
  })])], 1), _vm.newWebNavList.length > 0 ? _c('m-nav2-ui20', {
    attrs: {
      "openState": _vm.isShowNav
    },
    on: {
      "changeView": _vm.changeView
    }
  }) : _vm._e()] : [_c('div', {
    staticClass: "m-header m-header-1"
  }, [_c('router-link', {
    staticClass: "info-wrapper",
    attrs: {
      "tag": "div",
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.companyLogoUrl,
      "alt": ""
    }
  })]), _c('p', [_vm._v(_vm._s(_vm.companyName))]), _c('router-link', {
    staticClass: "mine",
    attrs: {
      "tag": "div",
      "to": "/user"
    }
  }, [_c('i', {
    staticClass: "icon-menu"
  })])], 1)]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };