/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2024/8/2 下午4:22
 */

import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import AlertPlugin from "vux/src/plugins/alert/index.js";
import Previewer from "vux/src/components/previewer/index.vue";
import LoadingPlugin from "vux/src/plugins/loading/index.js";
import ToastPlugin from "vux/src/plugins/toast/index.js";
import TransferDom from "vux/src/directives/transfer-dom/index.js";
import WechatPlugin from "vux/src/plugins/wechat/index.js";
import ConfirmPlugin from "vux/src/plugins/confirm/index.js";
import '@/common/css/quill.snow.css';
import App from './App';
import router from './router';
import store from './store';
import '@/common/js/permission'; // 权限
import '@/common/stylus/index.styl';
import sensors from 'sa-sdk-javascript';
import { prototypeAddFunction, setAnonymousId, sha256AndBase64 } from '@/common/js/util';
import { globalInterval, clearLocalStorage } from './common/js/dataCenter';
import LuckDraw from 'vue-luck-draw';
import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/lib/vue-form-generator.css';
import microApp from '@micro-zoe/micro-app';
import '@/common/js/microApp';
import '@/common/js/vant';
import { mixin_socket } from "common/js/socket/mixin_socket";
import { ENV } from "@/common/js/util";
import Aegis from "aegis-web-sdk";
import VConsole from 'vconsole';
// 解决sass-activity中移动端上传视频$未定义的问题
import $ from 'jquery';
let formDesignerId = 625,
  href = window.location.href,
  baseApi = null,
  isLocal = href.indexOf('localhost') > 0 || href.indexOf(':6003') > 0;
if (href.indexOf('.100live.') > 0 || isLocal) {
  formDesignerId = 1180; // 测试
  baseApi = 'https://mg.test.100api.cn';
} else if (href.indexOf('.test.') >= 0) {
  baseApi = 'https://mg.100api.cn';
  formDesignerId = 804;
}
window.jQuery = $;
window.$ = $;
// vant组件
import { Slider, Field, Button, Icon, Overlay, Tab, Tabs, List, ActionSheet, Dialog } from 'vant';
Vue.use(VueFormGenerator, {
  appId: formDesignerId,
  baseApi: baseApi
});
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Slider);
Vue.use(ActionSheet);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Overlay);
Vue.use(Button);
Vue.use(Icon);
Vue.use(List);
Vue.config.productionTip = false;
if (window.location.href.indexOf('debug') >= 0) {
  new VConsole();
}
microApp.start({
  inline: false,
  // 开启inline后，被提取的js会作为script标签插入应用中运行，在开发环境中更方便调试。开启inline后会稍微损耗性能，一般在开发环境中使用。
  destroy: false,
  // 开启destroy，子应用在卸载后会清空缓存资源，再次渲染时重新请求数据
  disableScopecss: true,
  // 禁用样式隔离可以提升页面渲染速度，在此之前，请确保各应用之间样式不会相互污染。
  disableSandbox: false,
  // 禁用沙箱可能会导致一些不可预料的问题，通常情况不建议这样做。
  shadowDOM: false,
  // shadowDOM具有更强的样式隔离能力，开启后，<micro-app>标签会成为一个真正的WebComponent。但shadowDOM在React框架及一些UI库中的兼容不是很好，经常会出现一些不可预料的问题，除非你很清楚它会带来的问题并有信心解决，否则不建议使用。
  'disable-memory-router': true,
  // 关闭虚拟路由系统
  'disable-patch-request': true,
  // 关闭对子应用请求的拦截
  globalAssets: {
    js: ['https://saas-material-1252497236.cos.accelerate.myqcloud.com/js/jweixin-1.6.0.js'] // js地址
  },

  plugins: {
    modules: {
      'activity': [{
        loader(code) {
          if (code.indexOf('sockjs-node') > -1) {
            code = code.replace('window.location.port', '6002');
          }
          return code;
        }
      }],
      'metting': [{
        loader(code) {
          if (code.indexOf('sockjs-node') > -1) {
            code = code.replace('window.location.port', '6008');
          }
          return code;
        }
      }]
    }
  }
});
globalInterval(); // 全局定时器
clearLocalStorage(); // 检查本地缓存 是否有需要清除的
Vue.use(LuckDraw);
prototypeAddFunction(Vue);
setAnonymousId(); // 匿名ID
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.config.productionTip = false;
Vue.prototype.$sensors = sensors;
Vue.prototype.$sha256AndBase64 = sha256AndBase64;
Vue.directive('transfer-dom', TransferDom);
/* 微信jssdk */
Vue.use(WechatPlugin);
Vue.use(AlertPlugin);
Vue.use(Previewer);
Vue.use(ToastPlugin);
Vue.use(LoadingPlugin);
Vue.use(ConfirmPlugin);
Vue.use(VueLazyload, {
  error: require('@/common/image/error.png'),
  loading: require('@/common/image/default_video_cover.png')
});
Vue.mixin(mixin_socket);
/**/
Vue.prototype.getTheme = function (val, skin) {
  if (val.indexOf(',') > -1) {
    const ary = val.split(',');
    let str = '';
    ary.forEach(item => {
      str += item + skin + ' ';
    });
    return str;
  } else {
    return val + skin;
  }
};
Vue.prototype.connectApi = function (array, str) {
  // 根据业务的不同进行api的动态生成
  if (!str) {
    return array[0] + array[1];
  } else {
    return array[0] + str + array[1];
  }
};
Vue.prototype.serviceApi = ['train/', 'classroom/'];
if (ENV() === 'prod') {
  // 线上环境上报
  new Aegis({
    id: 'mZKyxHLW19J6gdKLR0',
    // 应用ID，即上报ID
    uin: '3544206588',
    // 用户唯一 ID（可选）
    reportApiSpeed: true,
    // 接口测速
    reportAssetSpeed: true,
    // 静态资源测速
    hostUrl: 'https://rumt-zh.com',
    // 上报域名，中国大陆 rumt-zh.com
    spa: true,
    // spa 应用页面跳转的时候开启 pv 计算
    afterRequest: res => {
      // console.log(res);
    }
  });
}
const isDebug = process.env.NODE_ENV !== 'production'; // 线上环境禁用vue调试
Vue.config.debug = isDebug;
Vue.config.devtools = isDebug;
Vue.config.productionTip = isDebug;
/* eslint-disable no-new */
microApp.router.setBaseAppRouter(router);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');