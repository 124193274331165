/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2023/12/18 下午4:01
 */

import { Api } from '@/api/api';
import fetch from 'common/js/fetch';
import { getToken, isWeixin, getParameter } from 'common/js/util';
let appId, redirectUrl, companyId;
export async function wxLoginOauth(obj) {
  companyId = obj.id;
  let wxLogInEnd = sessionStorage.getItem('wxLogInEnd');
  if (!isWeixin() || getToken() || wxLogInEnd) {
    sessionStorage.setItem('wxLogInEnd', '1');
    return;
  }
  if (obj['wxlogin']) {
    // SaaS微信登录
    getConfig();
  } else if (obj['middleWechat']) {
    // 中台微信授权
    middleman();
  }
}
const getConfig = () => {
  fetch.get(Api.common.getWechatLoginConfig).then(res => {
    if (res.data) {
      appId = res.data;
      getRedirectUrl(appId, item => {
        SaaS(item['redirectUri']);
      });
    }
  });
};

/*
* SaaS微信登录
* */
const SaaS = redirect_uri => {
  let wxCode = getParameter('code');
  if (!wxCode) {
    let state = 'wechatCode';
    let redirectUri = encodeURIComponent(redirect_uri);
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
    sessionStorage.setItem('wxLogInEnd', '1');
    window.location.replace(url);
  }
};
const getRedirectUrl = (app_id, callBack) => {
  let path = encodeURIComponent(window.RedirectPath || window.FuturePath || '/');
  let path2 = encodeURIComponent(window.location.origin + '/#' + (window.RedirectPath || window.FuturePath) || '/');
  redirectUrl = window.location.origin + `/#/relevancePhone?appId=${app_id}&redirect_path=${path}&redirect_end='1'`;
  callBack({
    redirectUri: redirectUrl,
    path: path2
  });
};
/*
* 中台微信登录
* */
const middleman = () => {
  let url = process.env.VUE_APP_WX_OAUTH;
  fetch.get(Api.wechat.getOauthAppId).then(res => {
    if (res.data) {
      appId = res.data;
      getRedirectUrl(res.data, item => {
        let replaceUrl = `${url}?tenantId=${companyId}&appId=${appId}&backUrl=${item.redirectUri}`;
        sessionStorage.setItem('wxLogInEnd', '1');
        if (process.env.VUE_APP_SSHY_COMPANY_ID.indexOf(companyId) >= 0) {
          // 森世海亚定制 自己注册
          window.location.replace(process.env.VUE_APP_SSHY_HOST + item.path);
        } else {
          window.location.replace(replaceUrl);
        }
      });
    }
  });
};