/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
/*
 * Sun-Sky  2024/8/2 下午4:22
 */

import store from '@/store';
export function createAsyncRouter() {
  const Login = () => import('@/components/login/login');
  const smsLogin = () => import('@/components/login/sms-login');
  const RelevancePhone = () => import('@/components/login/relevancePhone'); // 第三方登录关联手机号
  const FindPassword = () => import('@/components/login/find-password');
  const Register = () => import('@/components/login/register');
  const UpdatePassword = () => import('@/components/user-center/update-password');
  const PurchaseHistory = () => import('@/components/user-center/purchase-history');
  const ApplyForm = () => import('@/components/apply/Apply-to-join');
  const upCredentials = () => import('@/components/apply/up-credentials-scroll');
  const SplashAd = () => import('@/components/apply/splash-ad');
  // 大转盘游戏
  const DrawGame = () => import('@/components/game/drawGame'); // 抽奖游戏活动
  const DrawGameIpad = () => import('@/components/game/drawGame/drawGame-ipad'); // 抽奖游戏活动 - Ipad 单人抽奖
  const WriteAddress = () => import('@/components/game/drawGame/game-address'); // 填写抽奖后收货地址
  const MyPrize = () => import('@/components/user-center/uesr-myPrize'); // 我的中奖记录
  // -----------------------新增部分--------------------------
  const lotteryV2 = () => import('@/components/game/drawGame/lotteryV2/lotteryV2');
  // 病例征集
  const CaseIndex = () => import('@/components/case/index.vue');
  const caseActivityList = () => import('@/components/case/caseActivity-list'); // 病例征集-活动列表
  const caseActivityDetails = () => import('@/components/case/caseActivity-details'); // 病例征集-活动详情
  const caseFormList = () => import('@/components/case/caseFormList.vue');
  const caseDetails = () => import('@/components/case/case-details'); // 病例征集-病例详情
  const caseFill = () => import('@/components/case/case-fill'); // 病例征集-病例征集
  const caseFillForm = () => import('@/components/case/case-fill-form'); // 病例征集-病例征集-表单填写
  const casePreviewForm = () => import('@/components/case/case-preview-form'); // 病例征集-病例征集-表单预览
  const caseRemark = () => import('@/components/case/base/Remark-input-single-page'); // 病例征集-点评
  const caseRemarkList = () => import('@/components/case/case-remark-list-single-page'); // 病例征集-点评列表

  const caseAuditDetails = () => import('@/components/case/caseAudit-details'); // 病例征集-审核详情
  const stageAuditDetails = () => import('@/components/case/stage-audit-details'); // 病例征集-审核详情
  const CaseImages = () => import('@/components/case/caseImages'); // 导出表格时使用 和业务无关系
  const CaseVideo = () => import('@/components/case/caseVideo'); // 导出表格时使用 和业务无关系
  const CaseFilePreview = () => import('@/components/case/caseFilePreview'); // 导出表格时使用 和业务无关系
  const inviteRemark = () => import('@/components/case/invite-remark'); // 导出表格时使用 和业务无关系

  // 答题
  const AnswerIndex = () => import('@/components/answer/index'); // 答题-首页
  const AnswerPaper = () => import('@/components/answer/test-paper'); // 答题-试卷
  const AnswerResults = () => import('@/components/answer/answer-results'); // 答题-结果
  const AnswerResultsSee = () => import('@/components/answer/results-see'); // 线上答题-答题卡
  const AnswerMy = () => import('@/components/answer/answer-my'); // 答题-我的答题
  const CompetitionIndex = () => import('@/components/answer/competition/index'); // 知识竞赛-首页
  const CompetitionRecord = () => import('@/components/answer/competition/record'); // 知识竞赛-我的答题记录
  const CompetitionRanking = () => import('@/components/answer/competition/Ranking'); // 知识竞赛-排行榜
  const CompetitionPaper = () => import('@/components/answer/competition/paper'); // 知识竞赛-答题
  const CompetitionResults = () => import('@/components/answer/competition/results'); // 知识竞赛-结果
  const CompetitionResultsSee = () => import('@/components/answer/competition/results-see'); // 知识竞赛-错题查看
  const CompetitionTeamList = () => import('@/components/answer/competition/team-list'); // 知识竞赛-团队列表
  const CompetitionTeamInfo = () => import('@/components/answer/competition/team-info'); // 知识竞赛-我的团队

  // 互动病例
  const InteractiveCaseIndex = () => import('@/components/interactiveCase/index'); // 互动病例-首页
  // 论文征集
  const PaperSubmissionIndex = () => import('@/components/paperSubmission/index'); // 论文征集-首页
  const MyPaperSubmission = () => import('@/components/paperSubmission/myPaper'); // 论文征集-我的论文

  /*
  * 病历故事
  * */
  const CaseStoryIndex = () => import('@/components/caseStory/index'); // 病历故事-首页
  const MyCaseStory = () => import('@/components/caseStory/myCaseStory'); // 病历故事-我的创作
  const CaseStoryAuditList = () => import('@/components/caseStory/Audit/list.vue'); // 病案故事-审核中心-审核列表
  const CaseStoryAuditDetails = () => import('@/components/caseStory/Audit/details.vue'); // 病案故事-审核中心-审核详情

  /*
  * 课件上传
  * */
  const CoursewareIndex = () => import('@/components/courseware/index'); // 课件上传-管理列表

  // 话题PK
  const TopicPkIndex = () => import('@/components/topicPk/index'); // 话题PK-首页
  // 互动交流
  const CommunicationIndex = () => import('@/components/communication/index'); // 互动病例-首页

  // 合同签约
  const SignRead = () => import('@/components/sign/read'); // 签约文件详情
  const SignCreate = () => import('@/components/sign/create'); // 签约文件详情
  const SignMy = () => import('@/components/sign/sign-my'); // 我的合约
  // 审核
  const auditList = () => import('@/components/audit/audit-list'); // 审核中心-列表
  const paperListDetails = () => import('@/components/audit/paperListDetails'); // 审核中心-论文列表
  const paperDetails = () => import('@/components/audit/paperDetails'); // 审核中心-论文列表-详情
  const paperCheckResult = () => import('@/components/audit/base/checkResult.vue'); // 审核中心-论文列表-详情-审核结果
  // 投票
  const votingActivity = () => import('@/components/voting/activity'); // 投票活动详情
  const votingInfo = () => import('@/components/voting/voti-info'); // 投票活动详情
  // 投票V2
  const VoteIndex = () => import('@/components/voting/Index'); // 病例投票首页
  const VoteRanking = () => import('@/components/voting/Ranking'); // 病例投票排行榜
  const Votenfo = () => import('@/components/voting/Info'); // 病例投票介绍
  const VoteDetail = () => import('@/components/voting/Detail'); // 病例投票排行榜
  // 投票V3
  const Vote3Index = () => import('@/components/votingV3/Index'); // 病例投票首页
  const Vote3Ranking = () => import('@/components/votingV3/Ranking'); // 病例投票排行榜
  const Vote3Info = () => import('@/components/votingV3/Info'); // 病例投票介绍
  const Vote3Detail = () => import('@/components/votingV3/Detail'); // 病例投票排行榜
  // 积分
  const integralRank = () => import('@/components/integral/rank'); // 积分排行榜
  const integralMall = () => import('@/components/integral/index'); // 积分商城
  const integralDetail = () => import('@/components/integral/detail'); // 积分商城
  const integralExchange = () => import('@/components/integral/exchange'); // 积分兑换
  const integralAddressList = () => import('@/components/integral/address-list'); // 我的地址
  const integralAddressInfo = () => import('@/components/integral/address-info'); // 我的地址 增加/编辑
  const MyIntegralInfo = () => import('@/components/integral/user-integral-info'); // 我的-积分收入详情
  const MyIntegralRecords = () => import('@/components/integral/user-integral-records'); // 我的-积分消费记录
  const MyIntegralRecordsInfo = () => import('@/components/integral/user-integral-records-info'); // 我的-积分消费-消费详情
  /* 问卷调查 */
  const questionnaireIndex = () => import('@/components/questionnaire/Index'); // 问卷调查
  const questionnaireForms = () => import('@/components/questionnaire/Forms'); // 问卷-问卷表单
  const questionnaireMySubs = () => import('@/components/questionnaire/my-subs'); // 问卷-问卷表单
  const questionnaireMySubsV3 = () => import('@/components/questionnaireV3/my-subs'); // 问卷3.0-问卷表单
  const questionnaireMySubsDetail = () => import('@/components/questionnaire/my-sub-detail'); // 问卷-问卷表单
  /* 调研问卷3.0 */
  const questionnaireIndexV3 = () => import('@/components/questionnaireV3/index'); // 问卷调查
  const QuestionnaireActiveHomeV3 = () => import('@/components/questionnaireV3/active-home'); // 调研问卷-活动首页
  const QuestionnaireV3Preview = () => import('components/questionnaireV3/preview'); // 调研问卷-预览
  /* 邀请函 */
  const Invitation = () => import('@/components/invitation/invitation');
  const InvitationDynamic = () => import('@/components/invitation/dynamic');
  const InvitationStyle2 = () => import('@/components/invitation/invitation-style2'); // 病例邀请函
  const AboutYibai = () => import('@/components/yibai/index');
  const AboutPrivacy = () => import('@/components/yibai/privacy');
  const AppDownload = () => import('@/components/yibai/appDownload');
  /**/
  const Section = () => import('@/components/recommend/section');
  const Home = () => import('@/components/recommend/Home');
  const MenuList = () => import('@/components/recommend/base/MenuList');
  const Recommend = () => import('@/components/recommend/web-index');
  const ViewRecord = () => import('@/components/user-center/view-record');
  const UserVideoList = () => import('@/components/user-center/view-videoList');
  const UserAwardList = () => import('@/components/user-center/view-AwardList');
  const UserAwardInof = () => import('@/components/user-center/view-AwardInfo');
  const Survey = () => import('@/components/detail/base/survey');
  const ShippingAddress = () => import('@/components/detail/base/shippingAddress');
  const UserCenter = () => import('@/components/user-center/user-center');
  const UserEdit = () => import('@/components/user-center/user-edit');
  const UserFillIn = () => import('@/components/user-center/user-fill-in');
  const UserInvite = () => import('src/components/taskPortraits/inviteTask/registerInvite/inviteUser');
  const activityInviteStats = () => import('@/components/taskPortraits/inviteTask/activityInvite/inviteSatsList');
  const InviteParticipateRecord = () => import('@/components/taskPortraits/inviteTask/registerInvite/participateRecord');
  const Wallet = () => import('@/components/user-center/user-wallet');
  const WalletAccount = () => import('@/components/user-center/user-wallet-account');
  const TaskCenter = () => import('@/components/user-center/taskCenter');
  const MyIntegration = () => import('@/components/user-center/user-integral');
  const UserMessage = () => import('@/components/user-center/user-message-v2');
  const ApplyManage = () => import('@/components/apply/applyManage');
  const ApplyMemberList = () => import('@/components/apply/applyMemberList');
  const ApplyList = () => import('@/components/apply/myApplyList');
  const ApplyDetails = () => import('@/components/apply/apply-manage-info');
  const ApplyDetails2 = () => import('@/components/apply/apply-manage-info2');
  const ApplyClassroomDetails = () => import('@/components/apply/apply-classroom-manage-info'); // 空中、互动课堂详情
  /**/
  const CmsColumn = () => import('@/components/columns/index');
  const VoteActivePage = () => import('@/components/vote/vote-active-page');
  // const VoteListPage = () => import('@/components/vote/vote-list-page')
  const ActivityInvitation = () => import('@/components/vote/activity-invitation');
  const Article = () => import('@/components/columns/all-article');
  const Details = () => import('@/components/details/details');
  /* new */
  const Conferences = () => import('@/components/recommend/conferences');
  const Conference = () => import('@/components/conference/conference');
  const PerfectInformation = () => import('@/components/apply/perfectInformation');
  const WxPerfectInformation = () => import('@/components/apply/wxPerfectInformation');
  // const ErrorPage = () => import('@/components/404')
  const ErrorPage311 = () => import('@/components/311');
  /* 我的活动 */
  const Activitieslist = () => import('@/components/my-activities/index');
  /* 我的互动交流 */
  const MyCommunication = () => import('@/components/communication/my-communication');

  /* 投票评比-组件路径 Start  */
  const VotingCompIndex = () => import('@/components/votingComp/index'); // 投票评比-首页
  const VotingCompList = () => import('@/components/votingComp/secondList'); // 投票评比-我的活动-上传作品/参与投票列表
  /*智能问答*/
  const Qa = () => import('@/components/qa/qa'); // 智能问答
  const QaPreview = () => import('@/components/qa/qa-preview'); // 智能问答附件预览
  const QaTab = () => import('@/components/qa/qa-tab'); // 智能问答
  /*活动日历*/
  const Calendar = () => import('@/components/calendar/calendar'); // 活动日历
  /*病例资料库*/
  const CaseDatabase = () => import('@/components/caseDatabase/caseDatabase.vue');

  /* 签到任务 Start*/
  const TaskSignInDetails = () => import('components/task/task-signIn/task-signIn-details.vue'); // 签到任务-详情
  /* 签到任务 End*/

  let routerArray = [{
    path: '/caseDatabase/*',
    component: CaseDatabase,
    name: '互动病例库',
    meta: {
      allowTouchMove: true
    }
  }, {
    path: '/calendar',
    component: Calendar,
    name: '活动日历',
    meta: {
      keepAlive: false,
      columnVisible: true
    }
  }, {
    path: '/qa',
    component: Qa,
    name: '智能问答'
  }, {
    path: '/qa-tab',
    component: QaTab,
    name: '智能问答'
  }, {
    path: '/qa-preview',
    component: QaPreview,
    name: '智能问答'
  }, {
    path: '/task-signIn/*',
    component: TaskSignInDetails,
    name: '签到任务首页',
    meta: {
      pageWhit: true
    }
  }, {
    path: '/smsLogin',
    component: Login,
    name: '登录',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/login',
    component: smsLogin,
    name: '短信登录',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/login/clear',
    component: smsLogin,
    name: '短信登录',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/relevancePhone',
    component: RelevancePhone,
    name: '微信登录',
    meta: {
      hiddenHeader: true,
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/findPassword',
    component: FindPassword,
    name: '找回密码',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/updatePassword',
    component: UpdatePassword,
    name: '修改密码',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/purchaseHistory',
    component: PurchaseHistory,
    name: '购买记录',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/applyForm/:service/:type/:id/:code?/:caseType?',
    component: ApplyForm,
    name: '申请加入',
    meta: {
      keepAlive: false,
      allowTouchMove: true
    }
  },
  // code 二次审核
  {
    path: '/upCredentials/:id/:contentType',
    component: upCredentials,
    name: '上传证件照',
    meta: {
      keepAlive: false
    }
  },
  // 上传证件照
  {
    path: '/upCredentialsSingle',
    component: upCredentials,
    name: '个人资料-上传证件照单独认证',
    meta: {
      keepAlive: false
    }
  },
  // 个人资料-上传证件照单独认证
  {
    path: '/splashAd/:service/:type/:id',
    component: SplashAd,
    name: '开屏广告',
    meta: {
      keepAlive: false
    }
  },
  // 开屏广告
  /* 我的活动 */
  {
    path: '/MyActivities',
    component: Activitieslist,
    name: '我的活动'
  }, /* 我的互动交流 */
  {
    path: '/myCommunication',
    component: MyCommunication,
    name: '我的互动交流'
  }, /* 抽奖、游戏类 */
  {
    path: '/luckyDraw/:type/:id',
    component: DrawGame,
    name: '抽奖'
  }, {
    path: '/luckyDrawApp/:type/:id/:uid/:token',
    component: DrawGame,
    name: 'app抽奖'
  }, {
    path: '/luckyDrawIpad/:type/:id/:uid/:token',
    component: DrawGameIpad,
    name: '单人抽奖'
  }, {
    path: '/luckyDraw/writeAddress/:id/:gameid/:prizeid',
    component: WriteAddress,
    name: '填写奖品收货地址'
  }, {
    path: '/lotteryV2/:id',
    component: lotteryV2,
    name: '抽奖'
  },
  // new抽奖
  /* 积分 */
  {
    path: '/integral/rank',
    component: integralRank,
    name: '积分排行榜'
  },
  // 积分排行榜
  {
    path: '/integral/mall',
    component: integralMall,
    name: '商品兑换',
    meta: {
      columnVisible: true,
      keepAlive: true
    }
  },
  // 积分商城
  {
    path: '/goodsList',
    component: integralMall,
    name: '商品兑换',
    meta: {
      columnVisible: true,
      keepAlive: true
    }
  },
  // 积分商城
  {
    path: '/integral/detail/:id',
    component: integralDetail,
    name: '兑换商品详情'
  },
  // 积分详情
  {
    path: '/integral/exchange/:id',
    component: integralExchange,
    name: '商品兑换'
  },
  // 积分兑换
  {
    path: '/myintegral/income/:type/:id',
    component: MyIntegralInfo,
    name: '积分收入详情'
  },
  // 积分收入详情
  {
    path: '/myintegral/records/:type',
    component: MyIntegralRecords,
    name: '积分消费记录'
  },
  // 积分消费记录
  {
    path: '/myintegral/records/:type/:id',
    component: MyIntegralRecordsInfo,
    name: '积分消费详情'
  },
  // 积分消费详情
  /* 病例征集 */
  {
    path: '/caseActivity/list/:navigationId',
    component: caseActivityList,
    name: '活动列表'
  },
  // 病例征集-活动列表
  {
    path: '/caseActivity/:activityId',
    component: caseActivityDetails,
    name: '活动详情'
  },
  // 病例征集-活动详情
  {
    path: '/caseFormList/:activityId',
    component: caseFormList,
    name: '表单入口',
    meta: {
      allowTouchMove: true
    }
  },
  // 病例征集-活动详情
  {
    path: '/caseDetails/:activityId/:caseId',
    component: caseDetails,
    name: '详情'
  },
  // 病例征集-病例详情
  {
    path: '/caseFill/:activityId/:formId/:caseId?/:parentFillId?',
    component: caseFill,
    name: '',
    meta: {
      allowTouchMove: true
    }
  },
  // 病例征集-采集病例
  {
    path: '/caseFillForm/:activityId/:formId/:caseId/:page',
    component: caseFillForm,
    name: '填写表单',
    meta: {
      tag: 'caseForm',
      allowTouchMove: true
    }
  },
  // 病例征集-采集病例-表单填写
  {
    path: '/caseIndex/*',
    component: CaseIndex,
    name: '医学研究首页',
    meta: {
      pageWhit: true
    }
  },
  // 医学研究-首页
  {
    path: '/caseFromPreview/:activityId/:caseId/:page?',
    component: casePreviewForm,
    name: '病例详情',
    meta: {
      allowTouchMove: true
    }
  },
  // 病例征集-采集病例-表单预览
  {
    path: '/caseRemark/:activityId/:caseId',
    component: caseRemark,
    name: '点评',
    meta: {
      allowTouchMove: true
    }
  },
  // 病例征集-点评
  {
    path: '/caseRemarkListMy/:activityId/:caseId',
    component: caseRemarkList,
    name: '我的点评',
    meta: {
      allowTouchMove: true
    }
  },
  // 病例征集-我的点评
  {
    path: '/caseRemarkList/:activityId/:caseId',
    component: caseRemarkList,
    name: '点评列表',
    meta: {
      allowTouchMove: true
    }
  },
  // 病例征集-点评列表
  {
    path: '/caseAudit/:activityId/:busId',
    component: caseAuditDetails,
    name: '审核中心-活动详情'
  },
  // 审核中心-活动详情
  {
    path: '/caseAuditDetails/:activityId/:caseId/:flowId?',
    component: caseDetails,
    name: '活动详情'
  },
  // 审核中心-病例详情(审核原因)
  {
    path: '/stageAuditDetails/:activityId/:caseId/:flowId?',
    component: stageAuditDetails,
    name: '审核阶段详情',
    meta: {
      allowTouchMove: true
    }
  },
  // 审核中心-病例详情(审核原因)
  {
    path: '/caseimages/:id',
    component: CaseImages,
    name: '活动详情',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  },
  // 病例征集-Excel打开链接预览图片
  {
    path: '/casevideo/:id',
    component: CaseVideo,
    name: '活动详情',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  },
  // 病例征集-Excel打开链接预览视频
  {
    path: '/caseupload/:id',
    component: CaseFilePreview,
    name: '活动详情',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  },
  // 病例征集-Excel打开链接预览文件
  {
    path: '/caseInviteRemark/:activityId/:caseId/:parentCaseFillId?',
    component: inviteRemark,
    name: '邀请点评专家'
  },
  // 病例征集-邀请专家点评链接
  /* 答题 */
  {
    path: '/answer/:answerId',
    component: AnswerIndex,
    name: '线上答题',
    meta: {
      pageWhit: true
    }
  },
  // 答题-首页
  {
    path: '/answerPaper/:answerId',
    component: AnswerPaper,
    name: '线上答题'
  },
  // 答题-答题试卷
  {
    path: '/answerResults/:answerId',
    component: AnswerResults,
    name: '答题结果'
  },
  // 答题-答题结果
  {
    path: '/answer/resultsSee/:answerId/:resultsId/:page?',
    component: AnswerResultsSee,
    name: '错题查看'
  },
  // 线上答题-答题卡
  {
    path: '/myanswer',
    component: AnswerMy,
    name: '我的线上答题'
  },
  // 答题-我的答题
  {
    path: '/competition/:answerId',
    component: CompetitionIndex,
    name: '知识竞赛',
    meta: {
      pageWhit: true
    }
  },
  // 知识竞赛-首页
  {
    path: '/competition/record/:answerId',
    component: CompetitionRecord,
    name: '我的挑战记录'
  },
  // 知识竞赛-我的挑战记录
  {
    path: '/competition/ranking/:answerId',
    component: CompetitionRanking,
    name: '排行榜'
  },
  // 知识竞赛-排行榜
  {
    path: '/competition/paper/:answerId',
    component: CompetitionPaper,
    name: '竞赛答题'
  },
  // 知识竞赛-答题
  {
    path: '/competition/results/:answerId',
    component: CompetitionResults,
    name: '竞赛答题结果'
  },
  // 知识竞赛-结果
  {
    path: '/competition/resultsSee/:answerId/:resultsId/:page?',
    component: CompetitionResultsSee,
    name: '错题查看'
  },
  // 知识竞赛-错题查看
  {
    path: '/competition/teamlist/:answerId',
    component: CompetitionTeamList,
    name: '团队排名'
  },
  // 知识竞赛-团队排名
  {
    path: '/competition/teamInfo/:answerId/:teamId',
    component: CompetitionTeamInfo,
    name: '我的团队'
  },
  // 知识竞赛-团队详情

  /* 互动病例 */
  {
    path: '/interactiveCase/*',
    component: InteractiveCaseIndex,
    name: '',
    meta: {
      pageWhit: true
    }
  },
  // 互动病例-首页
  //  论文征集
  {
    path: '/paperSubmission/*',
    component: PaperSubmissionIndex,
    name: '',
    meta: {
      pageWhit: true
    }
  },
  // 论文征集-首页
  {
    path: '/myPaper/:id',
    component: MyPaperSubmission,
    name: ''
  },
  // 论文征集-我的论文
  {
    path: '/my/myPaper/:id',
    component: MyPaperSubmission,
    name: ''
  },
  // 论文征集-我的论文

  /* 病历故事-路由 Start */
  {
    path: '/my/myCaseStory/:id',
    component: MyCaseStory,
    name: '我的创作',
    meta: {
      pageWhit: true,
      dynamicTitle: 'caseStoryTitleValue'
    }
  },
  // 病历故事-我的创作
  {
    path: '/caseStory/*',
    component: CaseStoryIndex,
    name: '病案故事-首页',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  }, {
    path: '/auditList/caseStoryList/:id',
    component: CaseStoryAuditList,
    name: '病案故事-审核列表',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  }, {
    path: '/auditList/caseStoryDetails/:userId/:auditId',
    component: CaseStoryAuditDetails,
    name: '病案故事-审核列表',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  }, /* 病历故事-路由 End */

  /* 课件上传-路由 Start */
  {
    path: '/courseware/*',
    component: CoursewareIndex,
    name: '课件上传',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  }, /* 课件上传-路由 End */

  //话题pk
  {
    path: '/topicPk/*',
    component: TopicPkIndex,
    name: '话题PK',
    meta: {
      pageWhit: true
    }
  },
  // 话题pk-首页
  /* 互动交流 */
  {
    path: '/communication/*',
    component: CommunicationIndex,
    name: '互动交流',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  },
  // 互动交流-首页
  // 投票评比
  {
    path: '/votingComp/*',
    component: VotingCompIndex,
    name: '投票评比首页',
    meta: {
      pageWhit: true
    }
  }, {
    path: '/votingComp2/secondlist/:id',
    component: VotingCompList,
    name: '上传作品/参与投票列表'
  }, /* 签约 */
  {
    path: '/sign/my/:tabcode?',
    component: SignMy,
    name: '我的合约'
  },
  // 签约-我的合约
  {
    path: '/sign/read/:sginId/:sort',
    component: SignRead,
    name: '签约详情'
  },
  // 签约-签约详情-详情查看
  {
    path: '/sign/create/:sginId/:sort',
    component: SignCreate,
    name: '我的签约-签约'
  },
  // 签约-签约详情-签约

  /* 问卷调查 */
  {
    path: '/questionnaire/:id',
    component: questionnaireIndex,
    name: '问卷调查',
    meta: {
      pageWhit: true
    }
  },
  // 问卷调查-首页
  {
    path: '/questionnaire/forms/:id',
    component: questionnaireForms,
    name: '问卷详情'
  },
  // 问卷调查-form表单
  {
    path: '/questionnaire/mySubs/:id',
    component: questionnaireMySubs,
    name: '问卷调查-提交记录'
  },
  // 问卷调查-我的提交记录
  {
    path: '/questionnaireV3/mySubs/:id',
    component: questionnaireMySubsV3,
    name: '问卷调查3.0-提交记录'
  },
  // 问卷调查3.0-我的提交记录
  {
    path: '/questionnaire/forms/:id/:submitNo',
    component: questionnaireMySubsDetail,
    name: '问卷调查-提交详情'
  },
  // 问卷调查-我的提交详情
  /* 调研问卷3.0 */
  {
    path: '/questV3HomePage/:id',
    component: QuestionnaireActiveHomeV3,
    name: '问卷调查3.0首页',
    meta: {
      pageWhit: true
    }
  }, {
    path: '/questionnaire-v3/:id/:formId?',
    component: questionnaireIndexV3,
    name: '问卷调查3.0',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  },
  // 问卷调查-首页
  {
    path: '/questionnaire-v3-preview',
    component: QuestionnaireV3Preview,
    name: '调研问卷3.0-预览',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  }, /* 审核 */
  {
    path: '/auditList',
    component: auditList,
    name: '审核中心'
  },
  // 病例征集-审核中心
  {
    path: '/paperListDetails/:id/:level/:configLevel',
    component: paperListDetails,
    name: ''
  },
  // 论文征集-审核中心
  {
    path: '/paperDetails/:id/:level/:configLevel',
    component: paperDetails,
    name: ''
  },
  // 论文征集-审核中心-详情
  {
    path: '/paperCheckResult/:id',
    component: paperCheckResult,
    name: '',
    meta: {
      pageWhit: true,
      allowTouchMove: true
    }
  },
  // 论文征集-审核中心-详情
  /* 投票 */
  {
    path: '/voting/:voteId',
    component: votingActivity,
    name: '投票活动'
  },
  // 投票-活动详情
  {
    path: '/voting/:voteId/:optionId',
    component: votingInfo,
    name: '投票详情'
  },
  // 投票-投票详情
  // 投票 V2
  {
    path: '/casevote/:voteId',
    component: VoteIndex,
    name: '投票活动',
    meta: {
      pageWhit: true,
      navcode: 'index'
    }
  },
  // 病例投票-首页
  {
    path: '/casevote/ranking/:voteId',
    component: VoteRanking,
    name: '投票活动排行榜',
    meta: {
      pageWhit: true,
      navcode: 'ranking'
    }
  },
  // 病例投票-排行榜
  {
    path: '/casevote/info/:voteId',
    component: Votenfo,
    name: '投票活动活动介绍',
    meta: {
      pageWhit: true,
      navcode: 'info'
    }
  },
  // 病例投票-详情介绍
  {
    path: '/casevote/:voteId/:optionId',
    component: VoteDetail,
    name: '投票项详情'
  },
  // 病例投票-投票项详情
  {
    path: '/votingV2/:voteId',
    component: VoteIndex,
    name: '病例投票',
    meta: {
      pageWhit: true,
      navcode: 'index'
    }
  },
  // 病例投票-首页
  {
    path: '/votingV2/ranking/:voteId',
    component: VoteRanking,
    name: '病例投票排行榜',
    meta: {
      pageWhit: true,
      navcode: 'ranking'
    }
  },
  // 病例投票-排行榜
  {
    path: '/votingV2/info/:voteId',
    component: Votenfo,
    name: '病例投票活动介绍',
    meta: {
      pageWhit: true,
      navcode: 'info'
    }
  },
  // 病例投票-详情介绍
  {
    path: '/votingV2/:voteId/:optionId',
    component: VoteDetail,
    name: '投票项详情'
  },
  // 病例投票-投票项详情
  /* 投票 V3 */
  {
    path: '/votingV3/:voteId',
    component: Vote3Index,
    name: '投票活动',
    meta: {
      navcode: 'index'
    }
  },
  // 投票-首页
  {
    path: '/votingV3/ranking/:voteId',
    component: Vote3Ranking,
    name: '投票活动排行榜',
    meta: {
      navcode: 'ranking'
    }
  },
  // 投票-排行榜
  {
    path: '/votingV3/info/:voteId',
    component: Vote3Info,
    name: '投票活动活动介绍',
    meta: {
      navcode: 'info'
    }
  },
  // 投票-详情介绍
  {
    path: '/votingV3/:voteId/:optionId',
    component: Vote3Detail,
    name: '投票项详情'
  },
  // 投票-投票项详情
  // /* 地址 */
  {
    path: '/address',
    component: integralAddressList,
    name: '我的地址'
  },
  // 我的地址
  {
    path: '/address/add',
    component: integralAddressInfo,
    name: '新增地址',
    meta: {
      isadd: true
    }
  },
  // 新增地址
  {
    path: '/address/up/:addressId',
    component: integralAddressInfo,
    name: '编辑地址',
    meta: {
      isadd: false
    }
  },
  // 编辑地址
  /* 邀请函 */
  {
    path: '/invitation/:type/:id',
    component: Invitation,
    name: '邀请 函',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/invitation/:service/:type/:id',
    component: Invitation,
    name: '邀请函',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/invitationCase/:caseActivityId',
    component: InvitationStyle2,
    name: '病例邀请函',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/invitationStyle2/:service/:activityId',
    component: InvitationStyle2,
    name: '邀请函 ',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/conferenceInvitation/:service/:type/:id',
    component: Invitation,
    name: '大会邀请函',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/invitationDynamic/:id',
    component: InvitationDynamic,
    name: '动态邀请函',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  },
  // id 邀请函ID
  {
    path: '/aboutYibai',
    component: AboutYibai,
    name: '关于医百',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/aboutPrivacy',
    component: AboutPrivacy,
    name: '隐私声明',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/appDownload',
    component: AppDownload,
    name: 'APP下载',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/',
    component: Recommend,
    name: '首页',
    meta: {
      keepAlive: false,
      indexVisible: true,
      theme: 2
    }
  }, {
    path: '/viewRecord',
    component: ViewRecord,
    name: '学习记录',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/viewVideoList',
    component: UserVideoList,
    name: '我的课程',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/viewAwardList',
    component: UserAwardList,
    name: '百福袋记录',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/viewAwardInfo/:id',
    component: UserAwardInof,
    name: '百福袋详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/viewVideoList/:service',
    component: UserVideoList,
    meta: {
      keepAlive: false
    }
  }, {
    path: '/applyList/:businessId?',
    component: ApplyList,
    name: '我的申请',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/user',
    component: UserCenter,
    name: '个人信息',
    meta: {
      keepAlive: true,
      indexVisible: true,
      theme: 2
    }
  }, {
    path: '/edit',
    component: UserEdit,
    name: '编辑个人信息',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/improveInformation',
    component: UserFillIn,
    name: '完善信息',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/personalInvite',
    component: UserInvite,
    name: '我的二维码',
    meta: {
      keepAlive: false,
      allowTouchMove: true
    }
  }, {
    path: '/involvementRecord',
    component: InviteParticipateRecord,
    name: '参与活动记录',
    meta: {
      keepAlive: false,
      allowTouchMove: true
    }
  }, {
    path: '/activityInviteStats',
    component: activityInviteStats,
    name: '邀请统计',
    meta: {
      keepAlive: false,
      allowTouchMove: true
    }
  }, {
    path: '/wallet',
    component: Wallet,
    name: '我的钱包',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/walletAccount',
    component: WalletAccount,
    name: '收支明细',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/taskCenter',
    component: TaskCenter,
    name: '任务中心',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/myprize',
    component: MyPrize,
    name: '我的中奖记录',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/myintegral',
    component: MyIntegration,
    name: '我的积分',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/userMessage',
    component: UserMessage,
    name: '我的消息',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/survey/:id',
    component: Survey,
    name: '调查问卷',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/shippingAddress/:id/:name',
    component: ShippingAddress,
    name: '填写收货地址',
    meta: {
      keepAlive: false
    }
  },
  /* {path: '/live/:id', component: Live, meta: {keepAlive: false}},
   {path: '/live/:id/:token', component: Live, meta: {keepAlive: false}},
   {path: '/herald/:id', component: Herald, meta: {keepAlive: false}}, */
  {
    path: '/home/:firstColumnId/:secondColumnId?/:thirdColumnId?',
    component: Home,
    meta: {
      keepAlive: false,
      columnVisible: true,
      backIcon: true,
      hiddenHeader: true,
      theme: 2
    }
  },
  // 新网站导航内容列表
  {
    path: '/menuList/:type?',
    component: MenuList,
    meta: {
      keepAlive: false,
      columnVisible: true,
      backIcon: true,
      hiddenHeader: true,
      theme: 2
    }
  }, {
    path: '/section/:id',
    component: Section,
    meta: {
      keepAlive: false,
      columnVisible: true
    }
  }, {
    path: '/section/:service/:id',
    component: Section,
    meta: {
      keepAlive: false,
      columnVisible: true
    }
  }, {
    path: '/videoList/:service/:id',
    component: Section,
    meta: {
      keepAlive: false,
      columnVisible: true
    }
  },
  /* {path: '/video/:id', component: Video, meta: {keepAlive: false}},
   {path: '/finish/:id', component: Finish, meta: {keepAlive: false}}, */
  /* 新组件 */
  {
    path: '/live/:id',
    component: Details,
    meta: {
      keepAlive: false
    }
  }, {
    path: '/applyOrLogin/:type/:id',
    redirect: '/details/:type/:id',
    name: '登录 申请',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/applyOrLogin/:service/:type/:id',
    redirect: '/details/:service/:id',
    name: '登录申请',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/details/:id',
    component: Details,
    name: '视频 详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/details/:service/:id',
    component: Details,
    name: '视频详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/details/:service/:id/:token',
    component: Details,
    name: '视频详 情',
    meta: {
      keepAlive: false
    }
  }, /**/
  // {path: '/repository', component: RepositoryList, name: '知识库', meta: {keepAlive: false}},
  // {path: '/repository/:id', component: RepositoryDetail, name: '知识库详情'},
  // /**/
  // {path: '/community', component: Community, name: '店员社区', meta: {keepAlive: false}},
  // {path: '/activity', component: Activity, name: '精彩活动', meta: {keepAlive: false}},
  /**/
  {
    path: '/applyManage/:businessId?',
    component: ApplyManage,
    name: '申请管理',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/applyMemBerList/:service/:id?',
    component: ApplyMemberList,
    name: '申请人员列表',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/applyDetails/:service/:id/:busid?',
    component: ApplyDetails,
    name: '申请详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/applyDetails2/:service/:id',
    component: ApplyDetails2,
    name: '申请详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/applyClassroomDetails/:service/:id/',
    component: ApplyClassroomDetails,
    name: '申请详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/column/:id',
    component: CmsColumn,
    name: '栏目',
    meta: {
      keepAlive: false,
      indexVisible: true
    }
  }, {
    path: '/column/article/:id',
    component: Article,
    name: '文章详情',
    meta: {
      keepAlive: false,
      indexVisible: true
    }
  }, /* 大会 */
  {
    path: '/conferences',
    name: '会议列表',
    component: Conferences,
    meta: {
      keepAlive: false,
      columnVisible: true
    }
  }, {
    path: '/conference/:id',
    component: Conference,
    meta: {
      keepAlive: false,
      columnVisible: true
    }
  }, /* 签到报名 */
  {
    path: '/perfectInformation/:service/:id/:type?',
    component: PerfectInformation,
    name: '完善信息',
    meta: {
      keepAlive: false
    }
  }, /* 小程序通过saas完善信息 */
  {
    path: '/perfectInformation',
    component: WxPerfectInformation,
    name: '完善信息',
    meta: {
      hiddenHeader: true
    }
  }, /* 投票活动 */
  {
    path: '/activityRank/:id',
    component: VoteActivePage,
    name: '投票活动详情',
    meta: {
      keepAlive: false
    }
  }, {
    path: '/activityInvitation/:id',
    component: ActivityInvitation,
    name: '投票邀请函',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/activityInvitation/:type/:id',
    component: ActivityInvitation,
    name: '活动邀请函',
    meta: {
      keepAlive: false,
      pageWhit: true
    }
  }, {
    path: '/311',
    component: ErrorPage311,
    name: '网络拥堵'
  }, {
    path: '*',
    component: Recommend
  }];
  if (store.getters.registerVisible) {
    routerArray.push({
      path: '/register',
      component: Register,
      name: '注 册',
      meta: {
        keepAlive: false,
        pageWhit: true
      }
    }, {
      path: '/register/:userId/:taskId?',
      component: Register,
      name: '注册',
      meta: {
        keepAlive: false,
        pageWhit: true
      }
    });
  }
  return routerArray;
}