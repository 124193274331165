/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.unshift.js";
/*
 * Sun-Sky  2024/3/26 上午11:07
 */

import router from '@/router';
import store from '@/store';
import DataCenterParams, { AssistMiniProgram, setCurrentRouter, setGlobalInviteCore } from './dataCenter';
import { getToken, setToken, removeToken, phoneverification, isWeixinXcx } from 'common/js/util';
import { Api } from '@/api/api';
import fetch from 'common/js/fetch';
import MtaH5 from 'mta-h5-analysis';
import sensors from './sensors';
import sensorsV2 from './sensorsV2';
import { addCount } from './statistics';
import { cdpSmsClick } from "@/common/js/sensorsV2/incidentReported";
const specialList = ['/index', '/video', '/herald', '/live'];
let routerList = [];
let isAddRoutesComplete = false; // 动态路由加载完成状态

router.beforeEach((to, from, next) => {
  // 邀请活动链接参数缓存处理
  setGlobalInviteCore(to);
  /*处理子应用 提示 未关闭前跳转到基座导致 提示框不自动关闭*/
  setTimeout(() => {
    let vanToast = document.querySelector('.van-toast');
    if (vanToast) vanToast.parentNode.removeChild(vanToast);
  }, 1000);
  if (isAddRoutesComplete) {
    setCurrentRouter(to); // 上报当前路由
    // 条件内为动态路由加载完成后， 请灵活运用
    // dcp短信链接上报
    let sendId = to.query.send_id;
    let cdp_uid = to.query.distinct_id || null; // 营销中心 大数据传值过来  需要在给传回去
    let utm_campaign = to.query.utm_campaign || null;
    if (sendId) {
      let campaignId_isSend = sessionStorage.getItem('campaignId_isSend');
      if (!campaignId_isSend || campaignId_isSend !== utm_campaign.toString()) {
        sessionStorage.setItem('campaignId_isSend', utm_campaign);
        cdpSmsClick(sendId, cdp_uid);
      }
    }
    AssistMiniProgram(to, from, getToken()); // TODO 小程序埋点
    sensors.quick('autoTrackSinglePage'); // 神策数据统计
    sensorsV2.quick('autoTrackSinglePage'); // 神策数据统计
    sensors.setProfile({
      companyId: store.getters.companyId,
      deviceId: sensorsV2.store._state.$device_id,
      video_userPageUniq: DataCenterParams.videoWatchAdd.video_userPageUniq,
      doctorOpenId: window.location.href.indexOf('doctorOpenId=') >= 0 ? window.location.href.split('doctorOpenId=')[1].split('&')[0] : null
    });
    sensorsV2.setProfile({
      deviceId: sensorsV2.store._state.$device_id,
      video_userPageUniq: DataCenterParams.videoWatchAdd.video_userPageUniq,
      doctorOpenId: window.location.href.indexOf('doctorOpenId=') >= 0 ? window.location.href.split('doctorOpenId=')[1].split('&')[0] : null
    });
    setTimeout(() => {
      sensorsV2.registerPage({
        // 公共属性
        product_name: 'saas',
        uid: store.getters.userId,
        cdp_uid: cdp_uid,
        company_id: store.getters.companyId
      });
    }, 1000);
    addCount(to, from).then(r => {}); // 医百临时数据统计方案
  }
  // add(to, from)
  /*
  * 存5条浏览记录
  * */
  if (routerList[routerList.length - 1] !== to.path) {
    routerList.unshift(to.fullPath);
  }
  if (routerList.length > 5) {
    routerList.splice(5, 1);
  }
  /*
  * 登陆后点击后退按钮 防止回到登陆页面
  * */
  if (to.path.indexOf('/login/clear') !== -1) {
    routerList = [];
  }
  store.dispatch('setRouterFootprint', routerList);
  // 借用token 调试实用
  if (to.query.token) {
    localStorage.setItem('Yibai-Token', to.query.token);
    // 获取当前 URL
    let currentUrl = window.location.href;
    let regex = new RegExp('([?&])token=[^&]*&?');
    // 从 URL 中移除匹配的参数
    let updatedUrl = currentUrl.replace(regex, '$1');
    // 更新浏览器地址栏中的 URL
    window.location.replace(updatedUrl);
  }
  // END
  beforeEachOther(to, from);
  if (window.serviceType) {
    beforeEachVerif(to, from, next);
  } else {
    const url = window.location.href;
    let phone = getQueryVariable(url, 'loginCode');
    let path = url.substring(0, url.indexOf('?'));
    if (phoneverification(phone)) {
      userAuthLogin(phone, path);
      return;
    }
    fetch.post(Api.common.isTokenExist).then(response => {
      if (!response.data) {
        store.dispatch('ClearUserInfo');
      }
      Promise.all([store.dispatch('SetConfig'), store.dispatch('SetService'), store.dispatch('BusActivityConfig')]).then(() => {
        if (store.getters.asyncRouter.length === 0) {
          Promise.all([store.dispatch('setNavigation'), store.dispatch('setNavGroupList'), store.dispatch('setIndexConfig'), store.dispatch('SetAsyncRouter', 1)]).then(() => {
            router.addRoutes(store.getters.asyncRouter);
            isAddRoutesComplete = true; // 动态路由加载完成
            // 如果携带邀请id 在楼上已经存到本地 这里重定向去掉参数信息防止刷新页面后重复执行
            to.query.invId ? next({
              path: to.path,
              replace: true
            }) : next({
              ...to,
              replace: true
            });
          });
        }
      }).catch(() => {
        next();
      });
    }).catch(error => {
      console.log(error);
      next();
    });
  }
});
const href = window.location.href;
router.afterEach(to => {
  /*
  * 百度统计
  * */
  if (store.getters.companyStatus) {
    /* eslint-disable */
    // let _hmt = _hmt || []
    let hm = document.createElement('script');
    hm.src = JSON.parse(store.getters.companyStatus);
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  }
  /*
  * 腾讯统计
  * */
  let sid = '';
  let cid = '';
  if (href.indexOf('.test.') > 0 || href.indexOf('.100live.') > 0 || href.indexOf('localhost') > 0) {
    sid = '500551854';
    cid = '500551893';
  } else {
    sid = '500649653';
    cid = '500649658';
  }
  MtaH5.init({
    'sid': sid,
    // 必填，统计用的appid
    'cid': cid,
    // 如果开启自定义事件，此项目为必填，否则不填
    'autoReport': 1,
    // 是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
    'senseHash': 0,
    // hash锚点是否进入url统计
    'senseQuery': 0,
    // url参数是否进入url统计
    'performanceMonitor': 0,
    // 是否开启性能监控
    'ignoreParams': [] // 开启url参数上报时，可忽略部分参数拼接上报
  });
  /*
  *  腾讯统计
  * */
  MtaH5.pgv();
  store.dispatch('SetLoadingStatus', false);
});
function beforeEachVerif(to, from, next) {
  if (!getToken()) {
    store.dispatch('SetLoadingStatus', false);
    if (to.meta.pageWhit) {
      next();
    } else if (to.meta.indexVisible && store.getters.indexVisible) {
      next();
    } else if (to.meta.columnVisible && store.getters.companyConfig['columnVisible']) {
      next();
    } else if (to.path.indexOf('activityRank') !== -1) {
      let id = to.path.replace(/^[^\d]*(\d+)[^\d]*$/, '$1');
      next({
        path: '/login' + '?activeId=' + id,
        replace: true
      });
    } else if (to.path.indexOf('/login') >= 0) {
      next();
    } else {
      // 天士力租户定制化
      if (store.getters.companyId === 124 && to.path.indexOf('/details') !== -1 && to.params.service === '5') {
        getVideoInfo(to, from, next);
      } else {
        next({
          path: '/login',
          replace: true
        });
      }
    }
  } else {
    // 天士力租户定制化
    if (store.getters.companyId === 124 && to.path.indexOf('/details') !== -1 && to.params.service === '5') {
      getVideoInfo(to, from, next);
    }
    if (to.fullPath.indexOf('879370554400841728') >= 0) {
      window.location.replace('https://khsw.100doc.com.cn/mobile/#/details/5/880477701192818688');
    }
    if (to.fullPath.indexOf('905239021976817664') >= 0) {
      window.location.replace('https://caogr.100doc.com.cn/mobile/#/details/9/905881988328591360');
    }
    /*
      * 神策  有用户id后  修改匿名id为 userId
      * */
    setTimeout(() => {
      sensors.identify(store.getters.userId, true);
    }, 500);
    setTimeout(() => {
      sensorsV2.identify(store.getters.userId, true);
    }, 500);
    if (to.path.indexOf('/applyOrLogin') !== -1 || to.path.indexOf('/invitation') !== -1 || to.path.indexOf('/activityInvitation') !== -1) {
      store.dispatch('SetLoadingStatus', false);
      next();
    } else if (to.path.indexOf('activityRank') !== -1) {
      fetch.post(Api.common.isTokenExist).then(response => {
        if (response.code === 0) {
          if (response.data) {
            next();
          } else {
            removeToken();
            let id = to.path.replace(/^[^\d]*(\d+)[^\d]*$/, '$1');
            next({
              path: '/login' + '?activeId=' + id,
              replace: true
            });
          }
        }
      }).catch(error => {
        if (error.code) {
          next({
            path: '/login',
            replace: true
          });
        }
      });
    } else {
      // 客户端手机预览问题(去掉已有的token，用参数上自带的token)
      if (to.path.indexOf('/live') !== -1 && to.path.length > 60 || to.path.indexOf('/details') !== -1 && to.path.length > 63) {
        removeToken();
      } else {
        store.dispatch('SetToken');
        if (to.path.indexOf('/details') !== -1) {
          // 如果携带邀请id 在楼上已经存到本地 这里重定向去掉参数信息防止刷新页面后重复执行
          to.query.invId ? next({
            path: to.path
          }) : next();
        } else {
          const enable = store.getters.enableCompleteInfo;
          if (store.getters.userId.length === 0) {
            store.dispatch('GetInfo').then(isUserInfoCompleted => {
              const path = completeInfoPath(enable, isUserInfoCompleted, to.path);
              path === 'next' ? next() : next(path);
            }).catch(() => {
              next({
                path: '/login',
                replace: true
              });
              store.dispatch('SetLoadingStatus', false);
            });
          } else {
            const path = completeInfoPath(enable, store.getters.isUserInfoCompleted, to.path);
            path === 'next' ? next() : next(path);
          }
        }
      }
    }
  }
}
function beforeEachOther(to, from) {
  window.lastPath = from.fullPath;
  // 记录有效后退路径
  const allowedPaths = ['smsLogin', 'findPassword', 'login', 'register', 'relevancePhone', 'improveInformation'];
  const isQuery = to.query && Object.keys(to.query).length > 0;
  const isToken = to.query && to.query.token;
  const targetPath = to.path.split('/')[1];
  if (!allowedPaths.includes(targetPath) || isQuery && !isToken) {
    window.FuturePath = to.fullPath;
  }
  if (isQuery && to.query.id && targetPath.includes('login')) {
    localStorage.setItem('inviteId', to.query.id);
  }
  store.dispatch('SetLoadingStatus', true);
  if (specialList.includes(to.path)) {
    if (!to.name) {
      document.title = '';
    } else {
      document.title = to.name;
    }
  }

  // 使用一个循环来清除所有定时器
  const intervals = ['intervalWatchVal', 'intervalWatch', 'intervalVal', 'intervalRemark', 'cssFormat', 'timer', 'watchAddTime', 'chatInterval', 'voteInterval', 'surveyInterval', 'luckyInterval', 'detailInfoInterval'];
  intervals.forEach(interval => window.clearInterval(window[interval]));
}
function userAuthLogin(phone, path) {
  fetch.post(Api.common.registToken, {
    phone
  }).then(response => {
    const data = response.data;
    if (data) setToken(data);
    window.location.replace(path);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }).catch(error => {
    console.log('error', error);
  });
}
function getQueryVariable(url, variable) {
  const endLink = url.substring(url.indexOf('?'), url.length);
  const query = endLink.substring(1);
  const vars = query.split('&');
  let phone = -1;
  vars.forEach(item => {
    const pair = item.split('=');
    if (pair[0] === variable) {
      phone = parseInt(pair[1]);
    }
  });
  return phone;
}

// 获取跳转天士力小程序的UrlLink
function getWechatUrlLink(to) {
  if (isWeixinXcx()) {
    return;
  }
  let businessId = to.params.service;
  let busId = to.params.id;
  if (!businessId || !busId) {
    return;
  }
  let params = {
    query: `taskId=${businessId}&eventId=${busId}&share_id=1&liveStatus=1&share_type=3&activity_type=yibai`,
    path: 'pages/page-video/pages/live_web_view/index'
    // path后期可以做成活的，租户在小后台配置
  };

  fetch.post(Api.wechat.getUrlLink, params).then(response => {
    const data = response.data;
    window.location.replace(data);
  }).catch(error => {
    console.log('error', error);
  });
}

// 无token获取课程详情
function getVideoInfo(to, from, next) {
  let businessId = to.params.service;
  let busId = to.params.id;
  if (!businessId || !busId) {
    return;
  }
  fetch.get(Api.video.getVideoInfoNtk + `${busId}`).then(response => {
    // tslTime：天士力租户定制化上线时间
    // 跳转小程序与否：上线前-不跳转，上线后-跳转
    const tslTime = new Date("2023-06-20 00:00:00").getTime();
    const cTime = response.data.createTime;
    if (cTime > tslTime) {
      getWechatUrlLink(to);
    } else {
      if (getToken()) {
        next();
      } else {
        next({
          path: '/login',
          replace: true
        });
      }
    }
  }).catch(error => {
    console.log('error', error);
    if (getToken()) {
      next();
    } else {
      next({
        path: '/login',
        replace: true
      });
    }
  });
}

// 完善注册信息跳转逻辑
const completeInfoPath = (enable, isCompleted, toPath) => {
  const hash = window.location.hash;
  if (toPath === '/improveInformation' && (isCompleted || !enable)) {
    store.dispatch('SetLoadingStatus', false);
    return '/';
  }
  ;
  if (!enable || isCompleted || toPath === '/improveInformation') return 'next';
  if (hash.includes('improveInformation')) {
    store.dispatch('SetLoadingStatus', false);
    return false;
  }
  ;
  store.dispatch('SetLoadingStatus', false);
  return '/improveInformation';
};