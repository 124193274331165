/* this file is transformed by vux-loader */
/* eslint-disable */
import fetch from 'common/js/fetch';
import { Api } from '@/api/api';
import store from '@/store';
const cms = {
  state: {
    isCmsRequest: false,
    cmsNavigation: {},
    // 新导航（前台无权限，根据接口返回数据渲染）
    newWebNavList: [],
    // 新网站导航
    bottomNavigationBar: [],
    // 底部动态导航
    indexConfig: {},
    // 导航-首页配置
    navGroupList: [],
    // 导航-分组列表
    cmsNav: [],
    // 导航 空中课堂中的
    cmsBanner: [],
    // 轮播图
    cmsColumn: [],
    // 首页栏目
    cmsFriLink: [],
    // 友情链接
    cmsAdsHome: {},
    // 主页的广告
    cmsAdsColumn: {},
    // 栏目页的广告
    cmsAdsDetail: {},
    // 详情页的广告
    cmsColumnNav: []
  },
  mutations: {
    SET_ISCMS: state => {
      state.isCmsRequest = true;
    },
    SET_CMS_NAVIGATION: (state, obj) => {
      state.cmsNavigation = obj;
    },
    SET_NEWWEBNAVLIST: (state, obj) => {
      state.newWebNavList = obj;
    },
    SET_INDEXCONFIG: (state, obj) => {
      state.indexConfig = obj;
    },
    SET_NAVGROUPLIST: (state, obj) => {
      state.navGroupList = obj;
    },
    SET_BOTTOM_NAVIGATION_BAR: (state, obj) => {
      state.bottomNavigationBar = obj;
    },
    SET_CMSNAV: (state, array) => {
      state.cmsNav = array;
    },
    SET_CMSBANNER: (state, array) => {
      state.cmsBanner = array;
    },
    SET_CMSCOLUMN: (state, array) => {
      state.cmsColumn = array;
    },
    SET_FRILINK: (state, array) => {
      state.cmsFriLink = array;
    },
    SET_CMSADSHOME: (state, obj) => {
      state.cmsAdsHome = obj;
    },
    SET_CMSADSCOLUMN: (state, obj) => {
      state.cmsAdsColumn = obj;
    },
    SET_CMSADSDETAIL: (state, obj) => {
      state.cmsAdsDetail = obj;
    },
    SET_CMSCOLUMNNAV: (state, array) => {
      state.cmsColumnNav = array;
    }
  },
  actions: {
    async setCms({
      commit,
      state
    }) {
      const response = await fetch.get(Api.cms.homepageConfNew, {}).catch(() => {});
      const data = response.data;
      commit('SET_ISCMS');
      if (data.banners) {
        // 移动端和pc端在这块key值不一样
        const bannerDatalist = data.banners.map((val, index) => {
          let item = {};
          item.img = val.picture;
          item.url = val.url;
          return item;
        });
        commit('SET_CMSBANNER', bannerDatalist);
      }
      if (data.friendLists) {
        commit('SET_FRILINK', data.friendLists);
      }
      if (data.sources) {
        const array = data.sources;
        for (let i = 0; i < array.length; i++) {
          if (Number(array[i].type) === 1) {
            commit('SET_CMSADSHOME', array[i]);
          }
          if (Number(array[i].type) === 2) {
            commit('SET_CMSADSCOLUMN', array[i]);
          }
          if (Number(array[i].type) === 3) {
            commit('SET_CMSADSDETAIL', array[i]);
          }
        }
      }
      if (data.columnnavigation) {
        commit('SET_CMSCOLUMNNAV', data.columnnavigation);
      }
    },
    async setNavigation({
      commit,
      state
    }) {
      if (store.getters.newWebsite) {
        const response = await fetch.get(Api.cms.frontNavigationList, {}).catch(() => {});
        let data = response.data;
        if (data) {
          // 过滤无效导航数据(14和15是AIGC的类型，不显示在导航中，只在PC网站中显示)
          let _data = data.map(item => {
            item.childNavigation = item.childNavigation.filter(item2 => !(!item2.type && item2.thirdNavigationList.length < 1));
            return item;
          }).filter(item => !(!item.parentNavigation.type && item.childNavigation.length < 1)).filter(item => item.parentNavigation.type != 14 && item.parentNavigation.type != 15);
          commit('SET_NEWWEBNAVLIST', _data);
          let bottomNavBar = _data.filter(item => item.parentNavigation.bottom === 1 || item.parentNavigation.type == 1).sort((a, b) => {
            if (a.parentNavigation.type == 1) return -1;
            if (b.parentNavigation.type == 1) return 1;
            return 0;
          });
          commit('SET_BOTTOM_NAVIGATION_BAR', bottomNavBar);
        }
      } else {
        const response = await fetch.post(Api.cms.navigationList, {}).catch(() => {});
        let data = response.data;
        if (data) {
          let navList = data.map(item => {
            item.menuTrain = false;
            return item;
          }).filter(item => item.businessId !== 7);
          commit('SET_CMS_NAVIGATION', navList);
        }
      }
    },
    async setIndexConfig({
      commit,
      state
    }) {
      if (store.getters.newWebsite) {
        const response = await fetch.get(Api.cms.homePageFind).catch(() => {});
        let data = response?.data;
        if (data?.config) {
          commit('SET_INDEXCONFIG', JSON.parse(data.config));
        }
      }
    },
    async setNavGroupList({
      commit,
      state
    }) {
      if (store.getters.newWebsite) {
        const response = await fetch.post(Api.cms.navigationGroupList, {}).catch(() => {});
        let data = response?.data;
        commit('SET_NAVGROUPLIST', data);
      }
    },
    async setColumns({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        fetch.get(Api.cms.frontColumnList).then(response => {
          const data = response.data;
          if (data) {
            commit('SET_CMSCOLUMN', data);
          }
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
export default cms;