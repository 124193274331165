/* this file is transformed by vux-loader */
/* eslint-disable */
// 神策数据统计
import sensors from 'sa-sdk-javascript';
sensors.init({
  server_url: 'https://vs.cmalive.cn/ga.gif',
  // 数据上次地址
  is_track_single_page: true,
  // 是否开启单页面
  use_app_track: true,
  show_log: false,
  // 控制台显示数据开
  heatmap: {
    // 热图设置 default开启 not_collect关闭
    clickmap: 'default',
    // 点击热图，收集点击事件
    scroll_notice_map: 'default' // 视区热图，收集页面区域停留时间
  }
});

export default sensors;