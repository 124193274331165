/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2023/3/14 下午3:37
 */

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import user from '@/store/modules/user';
import { mixin_socket } from "@/common/js/socket/mixin_socket";
const socket = {
  state: {
    socketUrl: '',
    checkInterval: null,
    // 断线重连时 检测连接是否建立成功
    websocket: null,
    stompClient: null
  },
  mutations: {
    WEBSOCKET_INIT(state, url) {
      if (state.stompClient == null || !state.stompClient.connected) {
        state.socketUrl = url;
        if (state.stompClient != null && state.websocket.readyState === SockJS.OPEN) {
          state.stompClient.disconnect(() => {
            this.commit('WEBSOCKET_CONNECT');
          });
        } else if (state.stompClient != null && state.websocket.readyState === SockJS.CONNECTING) {
          return;
        } else {
          this.commit('WEBSOCKET_CONNECT');
        }
        if (!state.checkInterval) {
          const interval = setInterval(() => {
            if (state.stompClient != null && state.stompClient.connected) {
              clearInterval(state.checkInterval);
              state.checkInterval = null;
            } else if (state.stompClient != null && state.websocket.readyState !== SockJS.CONNECTING) {
              // websocket的状态为open 但是stompClient的状态却是未连接状态，故此处需要把连接断掉 然后重连
              state.stompClient.disconnect(() => {
                this.commit('WEBSOCKET_CONNECT');
              });
            }
          }, 2000);
          state.checkInterval = interval;
        }
      } else {
        console.log('连接已建立成功，不再执行');
      }
    },
    WEBSOCKET_CONNECT(state) {
      const _this = this;
      const websock = new SockJS(state.socketUrl);
      state.websocket = websock;
      // 获取STOMP子协议的客户端对象
      const stompClient = Stomp.over(websock);
      stompClient.debug = null; // 关闭控制台打印
      stompClient.heartbeat.outgoing = 100000;
      stompClient.heartbeat.incoming = 100000; // 客户端不从服务端接收心跳包
      // 向服务器发起websocket连接
      stompClient.connect({
        userId: user.state.userId
      },
      // 此处注意更换自己的用户名，最好以参数形式带入
      frame => {
        mixin_socket.methods.global_websocket_receive_message();
      }, () => {
        // 第一次连接失败和连接后断开连接都会调用这个函数 此处调用重连
        setTimeout(() => {
          if (!state.checkInterval) {
            _this.commit('WEBSOCKET_INIT', state.socketUrl);
          }
        }, 5000);
      });
      state.stompClient = stompClient;
    },
    WEBSOCKET_SEND(state, p) {
      state.stompClient.send(p.topic, {}, p.data);
    },
    WEBSOCKET_UNSUBSRCIBE(state, p) {
      state.stompClient.unsubscribe(p);
      for (let i = 0; i < state.listenerList.length; i++) {
        if (state.listenerList[i].topic === p) {
          state.listenerList.splice(i, 1);
          break;
        }
      }
    }
  },
  actions: {
    WEBSOCKET_INIT({
      commit
    }, url) {
      commit('WEBSOCKET_INIT', url);
    },
    WEBSOCKET_SEND({
      commit
    }, p) {
      commit('WEBSOCKET_SEND', p);
    },
    WEBSOCKET_UNSUBSRCIBE({
      commit
    }, p) {
      commit('WEBSOCKET_UNSUBSRCIBE', p);
    },
    // 接口形式推送消息
    PUSH_CODE({
      commit
    }, {
      subjectIcfRecordId,
      code
    }) {
      const params = {
        subjectIcfRecordId: subjectIcfRecordId,
        code: code
      };
      console.log('store推送消息', params);
      // pimessagepush(params)
    }
  }
};

export default socket;