/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2023/10/30 下午2:40
 */

import { Api } from '@/api/api';
import user from "@/store/modules/user";
import store from "@/store/index";
export const mixin_socket = {
  data() {
    return {};
  },
  methods: {
    // websocket建立链接
    global_websocket_connect() {
      const wsUrl = Api.socket.server;
      store.dispatch('WEBSOCKET_INIT', wsUrl); // 建立链接
    },

    // 发送消息
    global_websocket_send_message(code) {
      const params = {
        subjectIcfRecordId: store.getters.subjectIcfRecordId,
        code: code
      };
      store.dispatch('PUSH_CODE', params);
    },
    // 接收订阅消息
    global_websocket_receive_message() {
      const stompClient = store.getters.stompClient;
      const interval = setInterval(() => {
        // 增改轮询解决刷新问题
        if (stompClient != null && stompClient.connected) {
          clearInterval(interval);
          if (user.state.userId) {
            stompClient.subscribe(`/topic/saas.notify.${user.state.userId}`, res => {
              let result = JSON.parse(res.body);
              result = {
                ...result,
                ...JSON.parse(result.payload)
              };
              store.dispatch('GetUnReadMsgCount');
              store.commit('INIT_POST_MSG_LIST');
              store.dispatch('GetPostMsgList', {
                pageSize: this.pageSize,
                pageNum: 1
              });
              // store.dispatch('GetPostMsgList', { pageSize: this.pageSize, pageNum: 1, params: 15 })
              // this.$store.commit('CONCAT_POST_MSG_LIST', result)
            });
          }
        } else {
          console.log('等待连接中');
        }
      }, 1000);
    },
    // 接收关联活动弹框
    websocketWatchInteractRelation(videoId, callback) {
      const stompClient = store.getters.stompClient;
      const interval = setInterval(() => {
        // 增改轮询解决刷新问题
        if (stompClient != null && stompClient.connected) {
          clearInterval(interval);
          if (videoId) {
            stompClient.subscribe(`/topic/saas.watchInteractRelation.${videoId}`, res => {
              let result = JSON.parse(res.body);
              result = {
                ...result,
                ...JSON.parse(result.payload)
              };
              callback({
                data: result
              });
            });
          }
        } else {
          console.log('等待连接中');
        }
      }, 1000);
    },
    /*
    * 取消订阅 视频关联活动
    * */
    websocketUnsubscribeWatchInteractRelation(videoId) {
      const stompClient = store.getters.stompClient;
      if (stompClient != null && stompClient.connected) {
        store.dispatch('WEBSOCKET_UNSUBSRCIBE', `/topic/saas.watchInteractRelation.${videoId}`);
      }
    },
    // 取消订阅消息
    global_websocket_unsubscribe() {
      const stompClient = store.getters.stompClient;
      if (stompClient != null && stompClient.connected) {
        store.dispatch('WEBSOCKET_UNSUBSRCIBE', `/topic/saas.notify.${user.state.userId}`);
      }
    }
  }
};