/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2022/10/28 下午4:39
 */

let env = 'd6be96a5968eb7f668187593b616256c';
let href = window.location.href;
if (href.indexOf('.test.') > 0) {
  env = 'eb14a29730cf39439f0aed9cd1faa57e';
} else if (href.indexOf('.100live.') >= 0 || href.indexOf('saas-test.') >= 0) {
  env = '9139d57dc4c1071c0798da94d525fa07';
}
// 神策数据统计
let sensorsV2 = window['sensorsDataAnalytic20221014'];
let debug = localStorage.getItem('debug') || null;
sensorsV2.init({
  server_url: `https://dotting.cmalive.cn/${env}/ya?bu=saas`,
  is_track_single_page: true,
  // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon',
  show_log: debug ? true : false,
  // 控制台显示数据开
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'default'
  }
});
sensorsV2.quick('autoTrack');
export default sensorsV2;