/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import fetch from 'common/js/fetch';
import { Api } from '@/api/api';
import { getToken } from 'common/js/util'; // 验权
import Fingerprint2 from 'fingerprintjs2';
import store from '@/store/index';
// eslint-disable-next-line no-unused-vars
export async function addCount(to, from) {
  let htmlUrl = window.location.protocol + '//' + window.location.host;
  let getDeviceId = await new Promise((resolve, reject) => {
    Fingerprint2.get(function (components) {
      const values = components.map(function (component, index) {
        if (index === 0) {
          // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
          return component.value.replace(/\bNetType\/\w+\b/, '');
        }
        return component.value;
      });
      // 生成最终id murmur
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);
      resolve(murmur);
    });
  });
  // console.log(getDeviceId)
  // eslint-disable-next-line no-unused-vars
  let loginStatus = '';
  if (getToken() !== '') {
    loginStatus = 1;
  } else {
    loginStatus = 0;
  }
  // 获取用户id
  // eslint-disable-next-line no-unused-vars
  let userId = '';
  // eslint-disable-next-line eqeqeq
  if (store.getters.userId == '') {
    store.watch(getters => getters.userId, () => {
      //    console.log(userId);
      userId = store.getters.userId;
    });
  } else {
    userId = store.getters.userId;
  }
  // console.log(userId)
  // 用户离开页面是判断离开时间
  // from to 路由拼接
  window.addEventListener('unload', function () {
    // 窗口关闭后
    let during = '';
    let startTime = localStorage.getItem('startTime');
    let endTime = Date.now();
    during = parseInt(endTime - startTime);
    localStorage.setItem('during', during);
    params.event.during = during;
    params.event.eventType = 3;
    if (articleArr2.indexOf('article') !== -1) {
      fetch.post(Api.dataCenter.push, params, true);
    }
  });
  const userAgent = navigator.userAgent;
  const currentURI = window.location.protocol + '//' + window.location.host + '/#' + from.path;
  let fromURI = from.path;
  if (fromURI === '/') {
    fromURI = htmlUrl;
  } else {
    fromURI = htmlUrl + '/#' + from.path;
  }
  let toUrl = to.path;
  if (toUrl === '/') {
    toUrl = htmlUrl;
  } else {
    toUrl = htmlUrl + '/#' + to.path;
  }
  let params = {
    device: {
      deviceId: getDeviceId,
      userAgent: userAgent
    },
    event: {
      currentURI: currentURI,
      element: 'article',
      eventType: 3,
      fromURI: fromURI,
      loginStatus: loginStatus,
      params: {},
      toURI: toUrl,
      userId: userId
    }
  };
  let articleArr = from.path;
  if (articleArr.indexOf('article') !== -1) {
    let startTime = localStorage.getItem('startTime');
    var endTime = Date.now();
    params.event.during = parseInt(endTime - startTime);
    fetch.post(Api.dataCenter.push, params, true);
  }
  let articleArr2 = to.path;
  let currentUrlArr = window.location.href;
  if (articleArr2.indexOf('article') !== -1) {
    localStorage.removeItem('startTime');
    var startTime = Date.now();
    // console.log('startTime', startTime)
    localStorage.setItem('startTime', startTime);
    setTimeout(function () {
      if (currentUrlArr.indexOf('article') !== -1) {
        params.event.currentURI = window.location.href;
        params.event.eventType = 2;
        fetch.post(Api.dataCenter.push, params, true);
      }
    }, 500);
  }
}