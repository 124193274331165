/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "footer-nav"
  }, [_vm._l(_vm.newBottomNavigationBar, function (item, index) {
    return _c('div', {
      key: item.id + index,
      class: _vm.selectId === item.id ? 'footer-nav-item footer-nav-item-select' : 'footer-nav-item',
      on: {
        "click": function ($event) {
          return _vm.selectDynamicNav(item);
        }
      }
    }, [item.parentNavigation?.type == 1 ? [_vm.selectId === '/' ? _c('i', {
      staticClass: "icon-footer-index-select"
    }) : _c('i', {
      staticClass: "icon-footer-index default-icon"
    })] : [_c('i', {
      class: `${item.parentNavigation?.iconClass}`
    })], _c('span', [_vm._v(_vm._s(item.parentNavigation?.name))])], 2);
  }), _vm.companyConfig.id.toString() === _vm.gwCompanyId ? _c('div', {
    class: _vm.selectId === 'home/3339/3358' ? 'footer-nav-item footer-nav-item-select' : 'footer-nav-item',
    on: {
      "click": function ($event) {
        return _vm.selectNav('home/3339/3358');
      }
    }
  }, [_vm.selectId === 'home/3339/3358' ? _c('i', {
    staticClass: "icon-footer-message-select"
  }) : _c('i', {
    staticClass: "icon-footer-message default-icon"
  }), _c('span', [_vm._v("学科资讯")])]) : _vm._e(), _vm.companyConfig.id.toString() === _vm.gwCompanyId ? _c('div', {
    class: _vm.selectId === 'openWeb' ? 'footer-nav-item footer-nav-item-select' : 'footer-nav-item',
    on: {
      "click": function ($event) {
        return _vm.openWeb('https://www.cdgowell.com/product.aspx?Type=3');
      }
    }
  }, [_vm.selectId === 'openWeb' ? _c('i', {
    staticClass: "icon-footer-productCenter-select"
  }) : _c('i', {
    staticClass: "icon-footer-productCenter default-icon"
  }), _c('span', [_vm._v("产品中心")])]) : _vm._e(), _c('div', {
    class: _vm.selectId === '/user' ? 'footer-nav-item footer-nav-item-select' : 'footer-nav-item',
    on: {
      "click": function ($event) {
        return _vm.selectNav('/user');
      }
    }
  }, [_vm.selectId === '/user' ? _c('i', {
    staticClass: "icon-footer-my-select"
  }) : _c('i', {
    staticClass: "icon-footer-my default-icon"
  }), _c('span', [_vm._v("我的")])])], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };