/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.weixin && !_vm.isApp,
      expression: "!weixin&&!isApp"
    }],
    staticClass: "m-weixin"
  }, [_vm._v(" 为保证浏览质量,建议使用微信浏览器打开本网站 "), _c('div', {
    staticClass: "close",
    on: {
      "click": _vm.closeTip
    }
  }, [_c('i', {
    staticClass: "icon-bold-close"
  })])]), _c('wx-follow')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };