/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "view-nav-li-tit"
  }, [_vm.token ? _c('div', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    }
  }, [_c('wx-open-launch-weapp', {
    attrs: {
      "id": "launch-btn",
      "username": "gh_24428b7218a3",
      "path": "personal/pages/activity/index",
      "extra-data": {
        'token': _vm.jingZhuToken
      }
    }
  }, [_c('script', {
    attrs: {
      "type": "text/wxtag-template"
    }
  }, [_vm._v(" <span style=\"font-size: 14px;color: #333333\">" + _vm._s(_vm.itemData.name) + "</span> ")])])], 1) : _c('div', {
    staticClass: "view-nav-li-tit"
  }, [_vm._v(" " + _vm._s(_vm.itemData.name) + " ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };