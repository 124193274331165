/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import base from './modules/base';
import user from './modules/user';
import config from './modules/config';
import rouerConfig from './modules/router';
import cms from './modules/cms';
import getters from './getters';
import video from './modules/video';
import socket from "@/common/js/socket/socket";
import message from './modules/message';
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    base,
    config,
    user,
    rouerConfig,
    cms,
    video,
    socket,
    message
  },
  getters
});
export default store;