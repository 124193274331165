/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "scrollX",
    staticClass: "scroll-wrapper-x"
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };