/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * @Author: wangxiaojie
 * @Date: 2023-01-19 17:13:34
 * @FilePath: /mobile/src/common/js/vant.js
 * @LastEditors: Freja
 * @LastEditTime: 2024-10-18 15:31:48
 */
import Vue from 'vue';
import Tab from 'vant/lib/tab';
import Tabs from 'vant/lib/tabs';
import NavBar from 'vant/lib/nav-bar';
import { Popup } from 'vant';
import { Dialog } from 'vant';
import { Badge } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Search } from 'vant';
import { Button } from 'vant';
import { Calendar } from 'vant';
import { Icon } from 'vant';
import { Stepper } from 'vant';
import { ActionSheet } from 'vant';
import { Overlay } from 'vant';
import { Loading } from 'vant';
import 'vant/lib/tab/style';
import 'vant/lib/tabs/style';
import 'vant/lib/nav-bar/style';
import 'vant/lib/toast/style';
import 'vant/lib/popup/style';
import 'vant/lib/dialog/style';
import 'vant/lib/badge/style';
import 'vant/lib/collapse/style';
import 'vant/lib/collapse-item/style';
import 'vant/lib/search/style';
import 'vant/lib/button/style';
import 'vant/lib/calendar/style';
import 'vant/lib/icon/style';
import 'vant/lib/stepper/style';
import 'vant/lib/action-sheet/style';
import 'vant/lib/overlay/style';
import 'vant/lib/loading/style';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Popup);
Vue.use(Badge);
Vue.use(Dialog);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Search);
Vue.use(Button);
Vue.use(Calendar);
Vue.use(Icon);
Vue.use(Stepper);
Vue.use(ActionSheet);
Vue.use(Overlay);
Vue.use(Loading);