/* this file is transformed by vux-loader */
/* eslint-disable */
const base = {
  state: {
    isLoading: false
  },
  mutations: {
    SET_LOADING: (state, isLoading) => {
      if (isLoading === false) {
        setTimeout(() => {
          state.isLoading = isLoading;
        }, 200);
      } else {
        state.isLoading = isLoading;
      }
    }
  },
  actions: {
    SetLoadingStatus({
      commit
    }, val) {
      return new Promise(resolve => {
        commit('SET_LOADING', val);
        resolve();
      });
    }
  }
};
export default base;