/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    on: {
      "click": _vm.closeOpen
    }
  }, [_c('transition', {
    attrs: {
      "name": "slideLeft"
    }
  }, [_vm.cmsNavigation.length > 0 ? _c('scroll', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openstate,
      expression: "openstate"
    }],
    ref: "scroll",
    staticClass: "nav-scroll",
    attrs: {
      "data": _vm.discList
    }
  }, [_c('div', {
    staticClass: "nav-ul"
  }, [_c('div', {
    staticClass: "view-nav-li"
  }, [_c('router-link', {
    staticClass: "view-nav-li-tit",
    attrs: {
      "to": "/",
      "tag": "div"
    }
  }, [_vm._v("首页")])], 1), _c('div', {
    staticClass: "view-nav-li"
  }, [_vm.isAppDownload ? _c('router-link', {
    staticClass: "view-nav-li-tit",
    attrs: {
      "to": "/appDownload",
      "tag": "div"
    }
  }, [_vm._v("APP下载")]) : _vm._e()], 1), _vm._l(_vm.cmsNavigation, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "view-nav-li"
    }, [item.businessId === 6 ? _c('router-link', {
      staticClass: "view-nav-li-tit",
      attrs: {
        "to": "/integral/mall",
        "tag": "div"
      }
    }, [_vm._v(_vm._s(item.alias ? item.alias : item.name))]) : _c('div', {
      staticClass: "view-nav-li-tit",
      on: {
        "click": function ($event) {
          return _vm.changeD(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.alias ? item.alias : item.name) + " "), item.navigationList.length > 0 ? _c('i', {
      class: _vm.openJson[item.id] ? 'icon-drop-down open' : 'icon-drop-down'
    }) : _vm._e()]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openJson[item.id],
        expression: "openJson[item.id]"
      }],
      staticClass: "view-subnav"
    }, _vm._l(item.navigationList, function (item2) {
      return _c('router-link', {
        key: item2.id,
        staticClass: "view-subnav-li-tit",
        attrs: {
          "tag": "div",
          "to": _vm._f("toPath")(item2)
        }
      }, [_vm._v(_vm._s(item2.name))]);
    }), 1)], 1);
  }), _vm.cmsColumnNav.length > 0 ? _c('div', {
    staticClass: "view-nav-li"
  }, [_c('div', {
    staticClass: "view-nav-li-tit",
    on: {
      "click": function ($event) {
        return _vm.changeD('cmsopenid');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.columnAlias) + " "), _c('i', {
    class: _vm.openJson.cmsopenid ? 'icon-drop-down open' : 'icon-drop-down'
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openJson.cmsopenid,
      expression: "openJson.cmsopenid"
    }],
    staticClass: "view-subnav"
  }, _vm._l(_vm.cmsColumnNav, function (item) {
    return _c('router-link', {
      key: 'cms_' + item.id,
      staticClass: "view-subnav-li-tit",
      attrs: {
        "tag": "div",
        "to": `/column/${item.id}`
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)]) : _vm._e()], 2)]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };