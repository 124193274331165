/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2022/10/21 下午12:42
 */
const Config = require('@/common/js/config');
const domain = Config.DOMAIN_NAME;
let locationHost = window.location.host;
if (locationHost.indexOf('localhost') >= 0 || window.location.port.indexOf('600') >= 0) {
  locationHost = domain;
}
let zhendoc = 'www.zhendoc.com';
let zhendocHost = '2018.zhendoc.com';
let bjmeda = 'bjmeda.org.cn';
let ssmeda = 'www.ssmeda.cn';
let bjmedaHost = 'xxzxy.100doc.com.cn';
let bjsswlHost = 'bjsswl.100doc.com.cn';
locationHost = locationHost === zhendoc ? zhendocHost : locationHost;
locationHost = locationHost.indexOf(bjmeda) >= 0 ? bjmedaHost : locationHost;
locationHost = locationHost.indexOf(ssmeda) >= 0 ? bjsswlHost : locationHost;
window.localStorage.setItem('locationHost', locationHost); // 有接口不走此axios时 方便取host
module.exports = {
  locationHost
};