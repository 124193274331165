/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    on: {
      "click": _vm.closeOpen
    }
  }, [_c('transition', {
    attrs: {
      "name": "slideLeft"
    }
  }, [_vm.MaxList.length > 0 ? _c('scroll', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openstate,
      expression: "openstate"
    }],
    ref: "scroll",
    staticClass: "nav-scroll",
    attrs: {
      "data": _vm.discList
    }
  }, [_c('div', {
    staticClass: "nav-ul"
  }, [_c('div', {
    staticClass: "view-nav-li"
  }, [_vm.isAppDownload ? _c('router-link', {
    staticClass: "view-nav-li-tit",
    attrs: {
      "to": "/appDownload",
      "tag": "div"
    }
  }, [_vm._v("APP下载")]) : _vm._e()], 1), _vm._l(_vm.MaxList, function (item, index) {
    return _c('div', {
      key: item.parentNavigation.id,
      staticClass: "view-nav-li"
    }, [item.parentNavigation.url === '晶珠民族医药大讲堂' ? _c('div', {
      staticClass: "view-nav-li-tit"
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.token,
        expression: "token"
      }],
      staticStyle: {
        "height": "100%",
        "width": "100%"
      }
    }, [_c('wx-open-launch-weapp', {
      attrs: {
        "id": "launch-btn",
        "username": "gh_24428b7218a3",
        "path": "pages/index/index",
        "extra-data": {
          'token': _vm.jingZhuToken
        }
      }
    }, [_c('script', {
      attrs: {
        "type": "text/wxtag-template"
      }
    }, [_vm._v(" <span style=\"font-size: 14px;color: #333333\">" + _vm._s(item.parentNavigation.name) + "</span> ")])])], 1), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.token,
        expression: "!token"
      }],
      staticStyle: {
        "height": "100%",
        "width": "100%"
      }
    }, [_vm._v(" " + _vm._s(item.parentNavigation.name) + " ")])]) : _c('div', {
      class: ['view-nav-li-tit', {
        'act': item.parentNavigation.id === _vm.firstColumnId && item.parentNavigation.type !== 8
      }],
      attrs: {
        "id": 'liNav' + item.parentNavigation.id
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.goHome(item, $event);
        }
      }
    }, [_vm._v(" " + _vm._s(item.parentNavigation.name) + " "), item.childNavigation && item.childNavigation.length > 0 && item.parentNavigation.havingChild === 1 ? _c('i', {
      class: _vm.openJson1[item.parentNavigation.id] ? 'icon-drop-down open' : 'icon-drop-down'
    }) : _vm._e()]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openJson1[item.parentNavigation.id],
        expression: "openJson1[item.parentNavigation.id]"
      }],
      staticClass: "view-subnav"
    }, _vm._l(item.childNavigation, function (item2) {
      return _c('div', {
        key: 'liSubNav' + item2.id,
        staticClass: "view-subnav-li-tit",
        class: {
          'hasThirdNavigationList': item2.thirdNavigationList.length > 0
        },
        attrs: {
          "id": 'liSubNav' + item2.id
        }
      }, [_c('div', {
        class: ['subnav-name', {
          'act': item2.id === _vm.secondColumnId && item2.type !== 8 && !item2.havingChild
        }],
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.subgoHome(item2, $event);
          }
        }
      }, [_c('span', [_vm._v(_vm._s(item2.name))]), item2.thirdNavigationList.length > 0 ? _c('i', {
        class: ['icon-arrow-down', {
          'open': _vm.openJson2[item2.id]
        }]
      }) : _vm._e()]), item2.thirdNavigationList.length > 0 ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.openJson2[item2.id],
          expression: "openJson2[item2.id]"
        }],
        staticClass: "view-level3Nav"
      }, _vm._l(item2.thirdNavigationList, function (item3) {
        return _c('div', {
          key: 'liLevel3Nav' + item3.id,
          class: ['view-level3Nav-li-tit', {
            'act': item3.id === _vm.thirdColumnId && item3.type !== 8
          }],
          attrs: {
            "id": 'liLevel3Nav' + item3.id
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.level3goHome(item3, $event);
            }
          }
        }, [_vm._v(_vm._s(item3.name))]);
      }), 0) : _vm._e()]);
    }), 0)]);
  })], 2)]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };