/* this file is transformed by vux-loader */
/* eslint-disable */
import { createAsyncRouter } from 'common/js/router';
import { constantRouterMap } from '../../router/index';
const rouerConfig = {
  state: {
    asyncRouter: [],
    routerFootprint: [] // 路由记录
  },

  mutations: {
    SET_ASYNC_ROUTER: (state, routers) => {
      state.asyncRouter = constantRouterMap.concat(routers);
    },
    SET_ROUTER_FOOTPRINT: (state, array) => {
      state.routerFootprint = array;
    }
  },
  actions: {
    // 配置设置
    SetAsyncRouter: ({
      commit,
      state
    }, num) => {
      const routers = createAsyncRouter(num);
      commit('SET_ASYNC_ROUTER', routers);
    },
    setRouterFootprint({
      commit,
      state
    }, data) {
      commit("SET_ROUTER_FOOTPRINT", data);
    }
  }
};
export default rouerConfig;